import { InitiativeType } from "../../../../../Types/LeadProject";

export const initiativeDescriptions: Record<InitiativeType, string> = {
  "Company Strategy / Management Push":
    "Initiatives associated with formal company strategy or any directives from upper management focusing on a specific topic and use cases.",
  "External Awareness Activities":
    "Initiatives associated with efforts by the Venture Client Unit to raise awareness outside the organization.",
  "External Event":
    "Initiatives associated with participation in or engagement with an event outside the organization.",
  "Internal Awareness Activities":
    "Initiatives associated with internal campaigns by the Venture Client Unit to increase awareness within the company.",
  "Network & Structure":
    "Initiatives associated with stakeholder networks and internal structures established by the Venture Client Unit, such as a Venture Client Program Manager network.",
  "Online Presence & Channels":
    "Initiatives associated with engagement through the Venture Client Unit's online presence and communication channels.",
  "Active Outreach":
    "Initiatives associated with the Venture Client Unit proactively reaching out to organizational units, presenting specific use cases or startups to generate interest in venture client activities.",
};
