import { Box, styled, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { ReactElement, useEffect, useRef, useState } from "react";
import { InitiativeNamesHttpService } from "../../../../../Http/Initiatives/InitiativeNames.http.service";
import theme from "../../../../../theme";
import { InitiativeName } from "../../../../../Types/InitiativeName";
import { getErrorMessage } from "../../../../../utils";
import DeleteOrRemoveModal from "../../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import ArchiveDeleteMenu from "./ArchiveDeleteMenu";
import useRoles from "../../../../../Hooks/useRoles";
import { VentureClient } from "../../../../../Types/VentureClient";

const MAX_CHAR = 50;

const InitiativeNameItemContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.borderOutline.soft}`,
  "& fieldset": { border: "none" },
}));

const StyledTextField = styled(TextField)(() => ({
  flexGrow: 1,
  border: "none",
  transition: "max-width 0.3s ease, padding 0.3s ease",
  textarea: {
    ":focus": {
      color: theme.palette.text.mediumEmphasis,
    },
  },
  "& .MuiInputBase-root": {
    padding: theme.spacing(2, 0),
    backgroundColor: "transparent",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    textFillColor: theme.palette.text.primary,
  },
}));

interface InitiativeNameItemProps {
  initiativeName: InitiativeName;
  currentEditingId: number | null;
  onEditStart(): void;
  onEditEnd(): void;
  onDelete(): void;
  onSave(updatedInitiativeName?: InitiativeName): void;
}

export default function InitiativeNameItem({
  initiativeName,
  currentEditingId,
  onSave,
  onDelete,
  onEditStart,
  onEditEnd,
}: InitiativeNameItemProps): ReactElement {
  const { canManage } = useRoles(undefined, undefined, {
    id: initiativeName.ventureClientId,
  } as VentureClient);
  const { enqueueSnackbar } = useSnackbar();
  const [currentValue, setCurrentValue] = useState(initiativeName.value);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const isEscapePressed = useRef(false);
  const isEnterPressed = useRef(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const hasValueChanged = () => currentValue !== initiativeName.value;

  const handleEnterKeyDown = () => {
    isEnterPressed.current = true;
    inputRef.current?.blur();
  };

  const handleEscapeKeyDown = () => {
    isEscapePressed.current = true;
    inputRef.current?.blur();
  };

  const handleBlur = () => {
    if (isEscapePressed.current) {
      isEscapePressed.current = false;
      return onEditEnd();
    }

    if (isEnterPressed.current) {
      isEnterPressed.current = false;
    }

    if (!currentValue) {
      return currentEditingId !== -1 ? setDeleteModalOpen(true) : onDelete();
    }

    if (!hasValueChanged()) {
      return onEditEnd();
    }

    return handleSave(initiativeName.id, currentValue);
  };

  const handleDelete = () => {
    if (initiativeName.id === -1) return onDelete();

    InitiativeNamesHttpService.deleteInitiativeName(initiativeName.id)
      .then(() => {
        onDelete();
        onEditEnd();
        enqueueSnackbar("Initiative deleted successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not delete the initiative: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      });
  };

  const handleArchive = () => {
    InitiativeNamesHttpService.archiveInitiativeName(initiativeName.id)
      .then(() => {
        onDelete();
        enqueueSnackbar("Initiative archived successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not archive the initiative name: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      });
  };

  const handleSave = async (id: number, value: string) => {
    const action = id === -1 ? "create" : "update";
    try {
      let data;
      if (action === "update") {
        data = await InitiativeNamesHttpService.updateInitiativeName(id, {
          value,
        });
      } else {
        data = await InitiativeNamesHttpService.createInitiativeName({
          initiativeType: initiativeName.initiativeType,
          ventureClientId: initiativeName.ventureClientId,
          value,
        });
      }
      onSave(data);
      onEditEnd();
      enqueueSnackbar(`Initiative ${action}d successfully`, {
        variant: "success",
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error as AxiosError);
      enqueueSnackbar(`Could not ${action} initiative name: ${errorMessage}`, {
        variant: "error",
      });
    }
  };

  const handleUnarchive = () => {
    InitiativeNamesHttpService.unarchiveInitiativeName(initiativeName.id)
      .then((data) => {
        onSave(data);
        enqueueSnackbar("Initiative unarchived successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not unarchive the initiative name: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      });
  };

  const isEditing = currentEditingId === initiativeName.id && !deleteModalOpen;

  useEffect(() => {
    setCurrentValue(initiativeName.value);
  }, [initiativeName]);

  return (
    <>
      <InitiativeNameItemContainer>
        <StyledTextField
          data-testid={`initiative-${initiativeName.id}`}
          disabled={!canManage}
          maxRows={3}
          inputRef={inputRef}
          value={currentValue}
          sx={{ input: { cursor: isEditing ? "text" : "pointer" } }}
          onChange={(e) => {
            setCurrentValue(e.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleEnterKeyDown();
            }
            if (event.key === "Escape") {
              handleEscapeKeyDown();
            }
          }}
          onBlur={handleBlur}
          onFocus={onEditStart}
          placeholder="Add Initiative"
          autoFocus={initiativeName.id === -1}
          multiline
          inputProps={{
            maxLength: MAX_CHAR,
            "data-testid": `initiative-${initiativeName.id}-input`,
          }}
        />
        {canManage && (
          <>
            {isEditing ? (
              <Typography color="text.disabled" pr={2}>
                {currentValue.length}/{MAX_CHAR}
              </Typography>
            ) : (
              <ArchiveDeleteMenu
                isArchived={!!initiativeName.archivedAt}
                openDeleteModal={() => {
                  onEditStart();
                  setDeleteModalOpen(true);
                }}
                onArchive={handleArchive}
                onUnarchive={handleUnarchive}
              />
            )}
          </>
        )}
      </InitiativeNameItemContainer>
      {deleteModalOpen && currentEditingId && (
        <DeleteOrRemoveModal
          id={currentEditingId}
          modalOpen={deleteModalOpen}
          handleCloseModal={() => {
            setDeleteModalOpen(false);
            onEditEnd();
          }}
          entity="Initiative"
          handleDelete={handleDelete}
          actionType="delete"
          onCancel={() => {
            setCurrentValue(initiativeName.value);
          }}
        />
      )}
    </>
  );
}
