import { Box, Button, Chip, Paper, Typography, styled } from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../../../theme";
import { Project } from "../../../../../Types/Project";
import MatchCard from "./MatchCard";
import { findLogo } from "../../../../../utils";
import ProjectStakeholdersModal from "./ProjectStakeholdersModal";

const Container = styled(Paper)(() => ({
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}));

const CardsContainer = styled(Box)(() => ({
  paddingTop: 0,
  gap: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

interface ProjectTeamLeadersSectionProps {
  project: Project;
  handleSave: () => void;
}

export default function ProjectTeamLeadersSection(
  props: ProjectTeamLeadersSectionProps
): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);
  const projectLeaderObject = {
    title: props.project.projectLeader?.name,
    subtitle1: props.project.projectLeader?.departmentCode,
    subtitle2: props.project.projectLeader?.businessUnit?.name,
    src: props.project.businessUnit.ventureClient.files
      ? findLogo(props.project.businessUnit.ventureClient.files, "companyLogo")
      : "",
  };
  const {
    projectLeader,
    projectLeadStartup,
    projectSponsor,
    projectTeam,
    otherStakeholders,
    programManager,
  } = props.project;

  const selectedStartup = props.project.opportunities.find(
    (opportunity) => opportunity.isSelectedForPilot === true
  )?.startup;

  const programManagerMatchOject = (() => {
    if (!props.project.programManager) return null;
    return {
      id: props.project.programManager.id,
      title: props.project.programManager?.name,
      subtitle1: props.project.programManager?.departmentCode,
      subtitle2: props.project.programManager?.businessUnit?.name,
      src: props.project.businessUnit.ventureClient.files
        ? findLogo(props.project.businessUnit.ventureClient.files, "Logo")
        : "",
    };
  })();

  const startupMatchObject = (() => {
    if (!selectedStartup) return null;

    if (props.project.projectLeadStartup) {
      return {
        id: selectedStartup.id,
        title: props.project.projectLeadStartup.name,
        subtitle1: props.project.projectLeadStartup.title,
        subtitle2: selectedStartup.name,
        src: selectedStartup.files ? findLogo(selectedStartup.files) : "",
      };
    } else {
      return {
        id: selectedStartup.id,
        title: selectedStartup.name,
        src: selectedStartup.files ? findLogo(selectedStartup.files) : "",
      };
    }
  })();

  const stakeholdersCount = [
    projectLeader ? [projectLeader] : [],
    projectLeadStartup ? [projectLeadStartup] : [],
    projectSponsor ? [projectSponsor] : [],
    projectTeam || [],
    otherStakeholders || [],
    programManager || [],
  ].flat().length;

  return (
    <>
      <Container>
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="h6">Main Stakeholders</Typography>
          <Chip label={stakeholdersCount} variant="counter" color="info" />
        </Box>

        <CardsContainer>
          <MatchCard
            {...projectLeaderObject}
            contact={props.project.projectLeader}
            handleSave={props.handleSave}
          />
          {programManagerMatchOject && (
            <MatchCard
              {...programManagerMatchOject}
              contact={props.project.programManager ?? undefined}
              handleSave={props.handleSave}
            />
          )}
          {startupMatchObject && (
            <MatchCard
              {...startupMatchObject}
              contact={props.project.projectLeadStartup ?? undefined}
              isStartup={true}
              handleSave={props.handleSave}
            />
          )}
        </CardsContainer>
        <Button
          onClick={() => setModalOpen(true)}
          variant="contained"
          color="secondary"
          fullWidth
        >
          View All Stakeholders
        </Button>
      </Container>
      {modalOpen && (
        <ProjectStakeholdersModal
          project={props.project}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          stakeholdersCount={stakeholdersCount}
        />
      )}
    </>
  );
}
