import { IconButton, IconButtonProps, Menu, MenuItem } from "@mui/material";
import { ReactElement, useState } from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

type MenuAction = {
  label: string;
  action(): void;
  icon?: ReactElement;
};

interface CustomMenuProps extends IconButtonProps {
  entity: string;
  actions: MenuAction[];
}

export default function CustomMenu({
  entity,
  ...props
}: CustomMenuProps): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        {...props}
        onClick={handleMenuOpen}
        data-testid={`${entity}-menu-button`}
      >
        <MoreVertOutlinedIcon fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& ul": {
            minWidth: "100px",
          },
        }}
      >
        {props.actions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={(event) => {
              event.stopPropagation();
              action.action();
              setAnchorEl(null);
            }}
            data-testid={`${action.label.toLowerCase()}-button`}
            sx={{
              paddingInline: 0.7,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {action.label}
            {action.icon}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
