import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  DialogContentText,
  Stack,
} from "@mui/material";
import { capitalizeFirstLetter } from "../../../../utils";
import { ReactElement } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import theme from "../../../../theme";
interface Props {
  id: number;
  entity: string;
  modalOpen: boolean;
  handleCloseModal(): void;
  handleDelete: (id: number) => void;
  onCancel?(): void;
  actionType: "delete" | "remove";
}

const DeleteOrRemoveModal = ({
  id,
  entity,
  modalOpen,
  handleCloseModal,
  handleDelete,
  actionType = "delete",
  onCancel,
}: Props): ReactElement => {
  const handleAction = () => {
    handleDelete(id);
    handleCloseModal();
  };

  return (
    <Dialog
      open={modalOpen}
      maxWidth="xs"
      data-testid="delete-modal"
      PaperProps={{
        sx: {
          "&.MuiDialog-paper": {
            p: 0,
            gap: 0,
          },
        },
      }}
    >
      <Stack p={theme.spacing(2, 2, 0)}>
        <DialogTitle
          sx={{
            ...theme.typography.subtitle1,
            justifyContent: "flex-start",
            px: 1,
          }}
        >
          <WarningAmberIcon color="error" />
          {capitalizeFirstLetter(actionType)} {capitalizeFirstLetter(entity)}
        </DialogTitle>
        <DialogContent sx={{ px: 1 }}>
          <DialogContentText>
            You are about to {actionType} this {entity}. Are you sure you want
            to continue?
          </DialogContentText>
        </DialogContent>
      </Stack>
      <DialogActions sx={{ p: theme.spacing(1) }}>
        <Button
          onClick={() => {
            handleCloseModal();
            onCancel && onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          data-testid={`delete-${id}-button`}
          variant="contained"
          onClick={handleAction}
        >
          {capitalizeFirstLetter(actionType)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrRemoveModal;
