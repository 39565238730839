import { Close } from "@mui/icons-material";
import {
  Box,
  Chip,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../../theme";
import ScrollableDialogContent from "../../../../UI/Modals/ScrollableDialogContent";
import { Project } from "../../../../../Types/Project";
import ContactInfo from "./ContactInfo";

interface ProjectStakeholdersModalProps {
  project: Project;
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  stakeholdersCount: number;
}

export default function ProjectStakeholdersModal({
  project,
  modalOpen,
  setModalOpen,
  stakeholdersCount,
}: ProjectStakeholdersModalProps): ReactElement {
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        data-testid="startup-team-modal"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            gap: theme.spacing(3),
            pb: theme.spacing(4),
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" gap={3}>
            <Typography variant="h3">Project Stakeholders</Typography>
            <Chip label={stakeholdersCount} variant="counter" color="info" />
          </Box>
          <IconButton
            size="small"
            onClick={() => setModalOpen(false)}
            sx={{ color: "icon.mediumEmphasis", alignSelf: "flex-start" }}
          >
            <Close fontSize="small" />
          </IconButton>
        </DialogTitle>
        <ScrollableDialogContent>
          <Stack gap={4}>
            <Stack gap={2}>
              <Typography variant="h6">Team Leaders</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ContactInfo
                    contacts={[project.projectLeader ?? []].flat()}
                    title="Project Leader"
                  />
                </Grid>
                <Grid item xs={6}>
                  <ContactInfo
                    contacts={[project.projectLeadStartup ?? []].flat()}
                    title="Project Lead (Startup)"
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack gap={2}>
              <Typography variant="h6">
                {project.businessUnit.ventureClient.name} Stakeholders
              </Typography>
              <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={6}>
                  <ContactInfo
                    contacts={[project.programManager ?? []].flat()}
                    title="Venture Client Program Manager"
                  />
                </Grid>
                <Grid item xs={6}>
                  <ContactInfo
                    contacts={[project.projectSponsor ?? []].flat()}
                    title="Project Sponsor"
                  />
                </Grid>
                <Grid item xs={12}>
                  <ContactInfo
                    contacts={[project.projectTeam ?? []].flat()}
                    title="Project Team"
                    fullwidth
                  />
                </Grid>
                <Grid item xs={12}>
                  {project.otherStakeholders &&
                    project?.otherStakeholders.length > 0 && (
                      <ContactInfo
                        contacts={[project.otherStakeholders ?? []].flat()}
                        title="Other Stakeholders"
                        fullwidth
                      />
                    )}
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </ScrollableDialogContent>
      </Dialog>
    </>
  );
}
