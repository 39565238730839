import {
  Avatar,
  Box,
  BoxProps,
  Typography,
  styled,
  Stack,
} from "@mui/material";
import theme from "../../../../theme";
import { ReactElement } from "react";
import { nameToInitials } from "../../../../utils";
import { PermIdentityOutlined } from "@mui/icons-material";

const ContactAvatar = styled(Avatar, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(
  ({
    $isVentureAssociate,
    $disabled,
  }: {
    $isVentureAssociate: boolean;
    $disabled?: boolean;
  }) => ({
    backgroundColor: $isVentureAssociate
      ? theme.palette.surface.brand.main
      : theme.palette.surface.primary.invert,
    width: theme.spacing(4),
    height: theme.spacing(4),
    ...theme.typography.body1,
    ...($disabled && {
      backgroundColor: theme.palette.surface.cool,
      color: theme.palette.icon.primaryInvert.mediumEmphasis,
    }),
  })
);

const Title = styled(Typography)(() => ({
  ...theme.typography.body2,
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
}));

export interface ContactNameProps extends BoxProps {
  avatar?: string;
  name?: string;
  title?: string;
  isVentureAssociate?: boolean;
  orientation?: "horizontal" | "vertical";
  invertColors?: boolean;
  gender?: string | null;
  founder?: boolean;
}
const ContactName = ({
  isVentureAssociate = false,
  name,
  title,
  avatar,
  orientation = "vertical",
  invertColors = false,
  gender,
  founder,
  onClick,
  ...props
}: ContactNameProps): ReactElement => {
  const hasData = !!name;

  const determineTextColor = () => {
    if (!hasData) return theme.palette.text.disabled;
    if (!!onClick) return theme.palette.text.action.main;
    if (invertColors) return theme.palette.text.primaryInvert.main;

    return theme.palette.text.primary;
  };

  const textColor = determineTextColor();

  const titleTextColor = (() => {
    if (!hasData)
      return invertColors
        ? theme.palette.text.primaryInvert.disabled
        : theme.palette.text.disabled;

    return invertColors
      ? theme.palette.text.primaryInvert.mediumEmphasis
      : theme.palette.text.mediumEmphasis;
  })();

  return (
    <Box
      display="flex"
      flexDirection={orientation === "horizontal" ? "row" : "column"}
      gap={2}
      onClick={onClick}
      {...props}
    >
      <ContactAvatar
        $isVentureAssociate={isVentureAssociate}
        $disabled={!hasData}
        alt={name}
        src={avatar}
      >
        {hasData ? nameToInitials(name) : <PermIdentityOutlined />}
      </ContactAvatar>
      <Stack justifyContent="center" width="100%">
        <Typography
          className="contact-name"
          variant="subtitle2"
          color={textColor}
          sx={{ cursor: !!onClick ? "pointer" : "default" }}
        >
          {name || title || "--"}
          {gender && (
            <Typography
              ml={0.5}
              variant="body2"
              color={titleTextColor}
              component="span"
            >
              ({gender})
            </Typography>
          )}
        </Typography>
        <Title variant="body2" color={titleTextColor}>
          {founder && "Founder "}
          {hasData && title ? title : "--"}
        </Title>
      </Stack>
    </Box>
  );
};

export default ContactName;
