import { Stack } from "@mui/material";
import { ReactElement } from "react";
import { VentureClient } from "../../../../Types/VentureClient";
import CompanyFocusAreas from "./FocusAreasView/CompanyFocusAreas";
import CompanyLeadInitiatives from "./LeadInitiatives/CompanyLeadInitiatives";

interface VentureClientSetupProps {
  ventureClientData: VentureClient;
  handleRefresh(): void;
}

export default function VentureClientSetup({
  ventureClientData,
  handleRefresh,
}: VentureClientSetupProps): ReactElement {
  return (
    <Stack gap={3}>
      <CompanyFocusAreas
        focusAreas={ventureClientData.focusAreas}
        ventureClient={ventureClientData}
        handleRefresh={handleRefresh}
      />
      <CompanyLeadInitiatives ventureClientId={ventureClientData.id} />
    </Stack>
  );
}
