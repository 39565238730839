/* eslint-disable @typescript-eslint/no-explicit-any */
import { Project } from "../Types/Project";
import { Requirement } from "../Types/Requirement";
import { Opportunity } from "../Types/Opportunity";
import { RequirementStartupFit } from "../Types/RequirementStartupFit";
import { StartupInfoFitExport } from "../Types/Startup";
import { compareOpportunitiesByRelevance } from "../utils";

export default class SolutionFitExportService {
  public static prepareAsCSV(
    project: Project,
    isCurated: boolean,
    opportunities: Opportunity[]
  ): {
    headers: { label: string; key: string }[];
    body: any[];
  } {
    const headers = this.createHeaders(project);
    const body = this.createBody(project, isCurated, opportunities);

    return { body, headers };
  }
  private static createHeaders(
    project: Project
  ): { label: string; key: string }[] {
    const headers = [
      { label: "Rating", key: "rating" },
      { label: "Cluster Name", key: "cluster" },
      { label: "Startup Name", key: "name" },
      { label: "Product Description", key: "description" },
      { label: "Founded", key: "founded" },
      { label: "Country", key: "billingCountry" },
      { label: "Website", key: "website" },
      { label: "Investment Amount (in Mio $)", key: "totalFunding" },
      { label: "Investment Stage", key: "currentInvestmentStage" },
      { label: "Investor Names", key: "investors" },
      { label: "Reference Clients", key: "referenceCustomers" },
    ] as { label: string; key: string }[];

    project.requirements
      ?.sort((a: Requirement, b: Requirement) => a.priority - b.priority)
      .map((requirement: Requirement) =>
        headers.push({
          label: requirement.description,
          key: "requirement-" + requirement.id.toString(),
        })
      );

    headers.push({ label: "Insights", key: "insights" });

    return headers;
  }

  private static createBody(
    project: Project,
    isCurated: boolean,
    opportunities: Opportunity[]
  ): any[] {
    const body: any[] = [];

    opportunities
      ?.filter(
        (opportunity: Opportunity) => opportunity.isQualified || isCurated
      )
      .sort(compareOpportunitiesByRelevance)
      .map((opportunity: Opportunity) => {
        const clusterName = project.solutionClusters.find(
          (cluster) => cluster.id === opportunity.solutionClusterId
        )?.name;

        const startupInfo: StartupInfoFitExport = {
          rating: opportunity.rating ? opportunity.rating : "",
          cluster: clusterName && this.sanitizeInput(clusterName),
          name: this.sanitizeInput(opportunity.startup.name),
          founded:
            opportunity.startup.dateFounded?.getFullYear() || "Undisclosed",
          billingCountry: this.sanitizeInput(
            opportunity.startup.billingCountry
          ),
          website: this.sanitizeInput(opportunity.startup.website),
          description: this.sanitizeInput(opportunity.startup.description),
          currentInvestmentStage: this.sanitizeInput(
            opportunity.startup.currentInvestmentStage
          ),
          referenceCustomers: this.sanitizeInput(
            opportunity.startup.referenceClients
              .flatMap((client) => client.name.replaceAll('"', ""))
              .join(", ")
          ),
          investors: this.sanitizeInput(
            opportunity.startup.investors
              .flatMap((investor) => investor.name.replaceAll('"', ""))
              .join(", ")
          ),
          totalFunding: opportunity.startup.totalFunding,
        };

        opportunity.requirementStartupFits
          ?.sort((a, b) => a.requirementId - b.requirementId)
          .map(
            (rsf: RequirementStartupFit) =>
              (startupInfo["requirement-" + rsf.requirementId.toString()] = `${
                rsf.status
                  ? this.sanitizeInput(this.mapRequirementFitStatus(rsf.status))
                  : ""
              }${rsf.status && rsf.fit ? " - " : ""}${
                rsf.fit ? this.sanitizeInput(rsf.fit) : ""
              }`)
          );

        startupInfo["insights"] = this.sanitizeInput(opportunity.insights);

        body.push(startupInfo);
      });

    return body;
  }

  public static mapRequirementFitStatus(status: string): string {
    switch (status) {
      case "Unfulfilled":
        return "Not Fulfilled";
      case "Undisclosed":
        return "Not Disclosed";
      default:
        return status;
    }
  }

  public static sanitizeInput(value: string): string {
    const harmfulCharacters = ["=", "+", "-", "@"];

    if (!value) {
      return "";
    }

    if (value && harmfulCharacters.includes(value.charAt(0))) {
      return "'" + value;
    }

    return value.replace(/"/g, '""');
  }
}
