export const InitiativesByOrigin = {
  "Organizational Unit": [
    "Internal Awareness Activities",
    "Network & Structure",
    "Company Strategy / Management Push",
    "External Event",
  ],
  "Venture Client Unit": [
    "Company Strategy / Management Push",
    "Active Outreach",
    "External Event",
  ],
  Startup: [
    "External Awareness Activities",
    "Online Presence & Channels",
    "External Event",
  ],
  "Third Party": [],
};

const generateOriginsByInitiative = (
  initiativesByOrigin: typeof InitiativesByOrigin
) => {
  const originsByInitiative: Record<string, string[]> = {};

  Object.entries(initiativesByOrigin).forEach(([origin, initiatives]) => {
    initiatives.forEach((initiative) => {
      if (!originsByInitiative[initiative]) {
        originsByInitiative[initiative] = [];
      }
      originsByInitiative[initiative].push(origin);
    });
  });

  return originsByInitiative;
};

export const OriginsByLeadInitiative =
  generateOriginsByInitiative(InitiativesByOrigin);
