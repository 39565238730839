import { Box, Chip, styled, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../../../theme";
import { InitiativeName } from "../../../../../Types/InitiativeName";
import {
  InitiativeType,
  LeadProjectOriginType,
} from "../../../../../Types/LeadProject";
import ManageLeadInitiativeModal from "./ManageLeadInitiativesModal";

const LeadInitiativeContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  transition: "all 0.1s ease-in-out",
  borderRadius: theme.shape.radius.minimal,
  "&:hover": {
    transform: "translateY(-1px)",
    boxShadow: theme.boxShadows[2],
    cursor: "pointer",
  },
});

const InitiativeCounterContainer = styled(Box)({
  display: "flex",
  gap: theme.spacing(2),
  width: 180,
  p: theme.spacing(1, 2),
  justifyContent: "flex-end",
});

interface LeadInitiativeProps {
  ventureClientId: number;
  name: InitiativeType;
  origins: LeadProjectOriginType[];
  initiativeNames: InitiativeName[];
  handleRefresh(): void;
}

export default function LeadInitiative({
  ventureClientId,
  name,
  origins,
  initiativeNames,
  handleRefresh,
}: LeadInitiativeProps): ReactElement {
  const [manageLeadInitiativeModalOpen, setManageLeadInitiativeModalOpen] =
    useState(false);

  const activeInitiatives = initiativeNames.filter(
    (initiative) => !initiative.archivedAt
  );

  return (
    <>
      <LeadInitiativeContainer
        key={name}
        onClick={() => {
          setManageLeadInitiativeModalOpen(true);
        }}
      >
        <Typography variant="subtitle2" width={280}>
          {name}
        </Typography>
        <Box display="flex" gap={1} flex={1} flexWrap="wrap">
          {origins.map((origin) => (
            <Chip key={origin} label={origin} variant="choice" />
          ))}
        </Box>
        <InitiativeCounterContainer>
          <Typography variant="body2" color="text.mediumEmphasis">
            Initiatives
          </Typography>
          <Chip
            variant="counter"
            color="primary"
            label={activeInitiatives.length}
          />
        </InitiativeCounterContainer>
      </LeadInitiativeContainer>
      {manageLeadInitiativeModalOpen && (
        <ManageLeadInitiativeModal
          ventureClientId={ventureClientId}
          initiative={name}
          initiativeNames={initiativeNames}
          origins={origins}
          modalOpen={manageLeadInitiativeModalOpen}
          closeModal={() => setManageLeadInitiativeModalOpen(false)}
          handleRefresh={handleRefresh}
        />
      )}
    </>
  );
}
