import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Chip,
  Stack,
  styled,
  Tab,
  Tabs,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../../../theme";
import { FocusArea, VentureClient } from "../../../../../Types/VentureClient";
import FocusAreaMenu from "./FocusAreaMenu";
import ManageFocusAreaModal from "./ManageFocusAreaModal";
import DeleteOrRemoveModal from "../../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import { FocusAreasHttpService } from "../../../../../Http/FocusArea/FocusAreas.http.service";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../../utils";
import { FOCUS_AREA_ARCHIVED_TOOLTIP } from "../../../../../Constants/TooltipText";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { a11yProps, TabPanel } from "../../../../UI/TabPanel";
import CustomAccordionSummary from "../../../../UI/CustomAccordionSummary";
import useRoles from "../../../../../Hooks/useRoles";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...theme.typography.body1,
    translate: "-50px 7px",
    maxWidth: "284px",
  },
}));

export const FocusAreaItem = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "56px",
  paddingLeft: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.borderOutline.soft}`,
}));

interface Props {
  focusAreas: FocusArea[];
  handleRefresh(): void;
  ventureClient: VentureClient;
}

const CompanyFocusAreas = ({
  focusAreas,
  ventureClient,
  handleRefresh,
}: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { canManage } = useRoles(undefined, undefined, ventureClient);

  const [manageFocusAreaModalOpen, setManageFocusAreaModalOpen] =
    useState(false);
  const [deleteFocusAreaModalOpen, setDeleteFocusAreaModalOpen] =
    useState(false);
  const [selectedFocusArea, setSelectedFocusArea] = useState<FocusArea | null>(
    null
  );
  const [tab, setTab] = useState(0);

  const sortedFocusAreas = focusAreas.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const [activeFocusAreas, archivedFocusAreas] = sortedFocusAreas.reduce(
    (acc, focusArea) => {
      focusArea.archivedAt ? acc[1].push(focusArea) : acc[0].push(focusArea);
      return acc;
    },
    [[], []] as [FocusArea[], FocusArea[]]
  );

  const closeModal = () => {
    setManageFocusAreaModalOpen(false);
    setDeleteFocusAreaModalOpen(false);
    setSelectedFocusArea(null);
  };

  const addFocusArea = () => {
    handleRefresh();
    closeModal();
  };

  const deleteFocusArea = async (focusAreaId: number) => {
    await FocusAreasHttpService.deleteFocusArea(focusAreaId)
      .then(() => {
        handleRefresh();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, { variant: "error" });
      })
      .finally(() => {
        closeModal();
      });
  };

  const updateFocusArea = () => {
    handleRefresh();
    closeModal();
  };

  const archiveFocusArea = async (focusAreaId: number) => {
    await FocusAreasHttpService.archiveFocusArea(focusAreaId)
      .then(() => {
        handleRefresh();
        enqueueSnackbar("Focus Area archived successfully", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Could not archive the focus area", {
          variant: "error",
        });
      });
  };

  const unarchiveFocusArea = async (focusAreaId: number) => {
    await FocusAreasHttpService.unarchiveFocusArea(focusAreaId)
      .then(() => {
        handleRefresh();
        enqueueSnackbar("Focus Area unarchived successfully", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Could not unarchive the focus area", {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Accordion defaultExpanded>
        <CustomAccordionSummary
          actionButton={
            canManage ? (
              <Button
                variant="contained"
                sx={{ ml: "auto" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setManageFocusAreaModalOpen(true);
                }}
                data-testid="add-focus-area-button"
              >
                Add Focus Area
              </Button>
            ) : undefined
          }
        >
          Focus Areas
        </CustomAccordionSummary>
        <AccordionDetails>
          <Stack gap={3}>
            <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
              <Tab
                label={
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle1">Active</Typography>
                    <Chip
                      variant="counter"
                      color="info"
                      label={activeFocusAreas.length}
                    />
                  </Box>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle1">Archived</Typography>
                    <StyledTooltip title={FOCUS_AREA_ARCHIVED_TOOLTIP}>
                      <InfoOutlinedIcon
                        fontSize="small"
                        htmlColor={theme.palette.icon.mediumEmphasis}
                      />
                    </StyledTooltip>
                    <Chip
                      variant="counter"
                      color="info"
                      label={archivedFocusAreas.length}
                    />
                  </Box>
                }
                {...a11yProps(1)}
              />
            </Tabs>
            <Box>
              <TabPanel value={tab} index={0}>
                {activeFocusAreas.length > 0 ? (
                  activeFocusAreas.map((focusArea) => (
                    <FocusAreaItem key={focusArea.id}>
                      <Typography>{focusArea.name}</Typography>
                      {canManage && (
                        <FocusAreaMenu
                          archiveFocusArea={() =>
                            archiveFocusArea(focusArea.id)
                          }
                          setEditModalOpen={() => {
                            setSelectedFocusArea(focusArea);
                            setManageFocusAreaModalOpen(true);
                          }}
                          setDeleteModalOpen={() => {
                            setSelectedFocusArea(focusArea);
                            setDeleteFocusAreaModalOpen(true);
                          }}
                        />
                      )}
                    </FocusAreaItem>
                  ))
                ) : (
                  <Typography variant="body1" color="text.disabled" mt={3}>
                    No Focus Areas have been added yet.
                  </Typography>
                )}
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {archivedFocusAreas.length > 0 ? (
                  archivedFocusAreas.map((focusArea) => (
                    <FocusAreaItem key={focusArea.id}>
                      <Typography>{focusArea.name}</Typography>
                      {canManage && (
                        <FocusAreaMenu
                          unarchiveFocusArea={() =>
                            unarchiveFocusArea(focusArea.id)
                          }
                          setEditModalOpen={() => {
                            setSelectedFocusArea(focusArea);
                            setManageFocusAreaModalOpen(true);
                          }}
                          setDeleteModalOpen={() => {
                            setSelectedFocusArea(focusArea);
                            setDeleteFocusAreaModalOpen(true);
                          }}
                        />
                      )}
                    </FocusAreaItem>
                  ))
                ) : (
                  <Typography variant="body1" color="text.disabled" mt={3}>
                    No Focus Areas have been archived yet.
                  </Typography>
                )}
              </TabPanel>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
      {manageFocusAreaModalOpen && (
        <ManageFocusAreaModal
          focusArea={selectedFocusArea}
          manageFocusAreaModalOpen={manageFocusAreaModalOpen}
          ventureClientId={ventureClient.id}
          updateFocusArea={updateFocusArea}
          addFocusArea={addFocusArea}
          closeModal={closeModal}
        />
      )}
      {deleteFocusAreaModalOpen && selectedFocusArea && (
        <DeleteOrRemoveModal
          id={selectedFocusArea.id}
          modalOpen={deleteFocusAreaModalOpen}
          handleCloseModal={closeModal}
          entity="Focus Area"
          handleDelete={deleteFocusArea}
          actionType="delete"
        />
      )}
    </>
  );
};

export default CompanyFocusAreas;
