import { ReactElement, Reducer, useEffect, useReducer, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from "@mui/material";
import { LeadProject, LeadProjectUseCase } from "../../../../Types/LeadProject";
import theme from "../../../../theme";
import CustomTextField from "../../../UI/InputFields/CustomTextField";
import CustomExpendableText from "../../../UI/CustomExpendableText";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import DeleteOrRemoveModal from "../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import { useSnackbar } from "notistack";
import useRoles from "../../../../Hooks/useRoles";

export const UseCaseTableCell = styled(TableCell)(() => ({
  verticalAlign: "top",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.surface.tertiary.dark,
    color: theme.palette.text.primaryInvert.main,
  },
  [`&.${tableCellClasses.body}`]: {
    borderColor: theme.palette.borderOutline.soft,
  },
}));

export const UseCaseTableRow = styled(TableRow)(() => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface UseCaseItemProps {
  useCase: LeadProjectUseCase;
  editMode: boolean;
  disableEdit: boolean;
  index: number;
  setEditMode: React.Dispatch<React.SetStateAction<number | null>>;
  saveUseCase: (useCase: LeadProjectUseCase) => void;
  updateUseCase: (useCase: LeadProjectUseCase) => void;
  deleteUseCase: (id: number) => void;
  handleCancel: (useCase: LeadProjectUseCase) => void;
  leadProject: LeadProject;
}

export default function UseCaseItem(props: UseCaseItemProps): ReactElement {
  const { canEdit } = useRoles(undefined, props.leadProject);
  const { enqueueSnackbar } = useSnackbar();
  const [useCase, setUseCase] = useReducer<
    Reducer<LeadProjectUseCase, Partial<LeadProjectUseCase>>
  >((state, newState) => ({ ...state, ...newState }), props.useCase);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    setUseCase(props.useCase);
  }, [props.useCase]);

  const handleSave = async () => {
    if (!useCase.name.trim()) {
      enqueueSnackbar("Please provide use case name", { variant: "error" });
      return;
    }
    if (props.useCase.id === -1) props.saveUseCase(useCase);
    else props.updateUseCase(useCase);
  };

  const handleCancelClick = () => {
    props.handleCancel(useCase);
  };

  return (
    <UseCaseTableRow>
      <UseCaseTableCell width="40%">
        <CustomTextField
          value={useCase.name}
          onChange={(e) => setUseCase({ name: e.target.value })}
          editMode={props.editMode}
          label="Title"
          hiddenLabel
          maxCharacter={255}
          fullWidth
          id={"use-case-title-" + useCase.id}
          data-testid={"use-case-title-" + useCase.id}
        />
      </UseCaseTableCell>
      <UseCaseTableCell width="60%">
        {props.editMode ? (
          <CustomTextField
            value={useCase.description}
            onChange={(e) => setUseCase({ description: e.target.value })}
            editMode={props.editMode}
            label="Description"
            fullWidth
            multiline
            rows={11}
            data-testid={"use-case-description-" + useCase.id}
          />
        ) : (
          <CustomExpendableText
            text={<Typography>{useCase.description}</Typography>}
          />
        )}
      </UseCaseTableCell>
      {canEdit && (
        <UseCaseTableCell width="auto">
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            {!props.editMode && (
              <IconButton
                sx={{ color: theme.palette.icon.action.main, padding: 0 }}
                onClick={() => props.setEditMode(useCase.id)}
                disabled={props.disableEdit}
                data-testid={"use-case-edit-" + useCase.id}
              >
                <EditOutlined />
              </IconButton>
            )}
            {props.editMode && (
              <Box display="flex" gap={2}>
                <Button onClick={handleSave} variant="contained">
                  Save
                </Button>
                <Button onClick={handleCancelClick}>Cancel</Button>
              </Box>
            )}
            {!props.editMode && (
              <IconButton
                sx={{ padding: 0 }}
                onClick={() => setDeleteModalOpen(true)}
                disabled={props.disableEdit}
                data-testid={"use-case-delete-" + useCase.id}
              >
                <DeleteOutline />
              </IconButton>
            )}
          </Box>
        </UseCaseTableCell>
      )}
      <DeleteOrRemoveModal
        modalOpen={deleteModalOpen}
        handleCloseModal={() => setDeleteModalOpen(false)}
        entity="Use Case"
        handleDelete={props.deleteUseCase}
        id={useCase.id}
        actionType="delete"
      />
    </UseCaseTableRow>
  );
}
