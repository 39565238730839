import { Close } from "@mui/icons-material";
import {
  Box,
  Chip,
  Collapse,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import ProjectTasksHttpService from "../../Http/ProjectTasks/ProjectTasks.http.service";
import theme from "../../theme";
import { UserTasks } from "../../Types/ProjectTask";
import NoTasksCard from "./NoTasksCard";
import TaskCard from "./TaskCard";

export default function TaskOverview(): ReactElement {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [userTasks, setUserTasks] = useState<UserTasks>({
    tasks: [],
    completedToday: 0,
  });

  useEffect(() => {
    getUserTasks();
  }, []);

  const getUserTasks = () => {
    ProjectTasksHttpService.getProjectTasksByUser().then((tasks) => {
      setUserTasks(tasks);
    });
  };

  return (
    <>
      <Box
        p={3}
        display="flex"
        gap={1}
        alignItems="center"
        onClick={() => setDrawerOpen(true)}
        sx={{
          cursor: "pointer",
          transition: "all 0.3s ease",
          "&:hover": {
            backgroundColor: theme.palette.surface.lightEmphasis,
          },
        }}
      >
        <Typography variant="caption" color="text.primaryInvert.main">
          Tasks
        </Typography>
        <Chip
          variant="counter"
          color="primary"
          label={userTasks.tasks.length}
        />
      </Box>
      <Drawer
        variant="temporary"
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Stack width={480} p={3} gap={4} maxHeight="100vh">
          <Stack gap={2}>
            <IconButton
              onClick={() => setDrawerOpen(false)}
              data-testid="close-drawer"
              sx={{ alignSelf: "flex-end" }}
            >
              <Close />
            </IconButton>
            <Typography variant="h3">Your Tasks</Typography>
          </Stack>
          <Stack gap={2} minHeight={0}>
            <Box
              display="flex"
              gap={1}
              alignSelf="flex-end"
              alignItems="center"
            >
              <Typography variant="subtitle2">Tasks Completed Today</Typography>
              <Typography
                variant="h6"
                color={
                  userTasks.completedToday > 0
                    ? "text.brand.main"
                    : "text.disabled"
                }
              >
                {userTasks.completedToday}
              </Typography>
            </Box>
            <Stack gap={2} overflow="scroll">
              <TransitionGroup>
                {userTasks.tasks.map((task) => {
                  return (
                    <Collapse key={task.id} sx={{ mb: 2 }}>
                      <TaskCard task={task} onUpdateTaskStatus={getUserTasks} />
                    </Collapse>
                  );
                })}
              </TransitionGroup>
              {userTasks.tasks.length === 0 && <NoTasksCard />}
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
