import { Box, Button, Typography, styled } from "@mui/material";
import { ReactElement, useContext, useState } from "react";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import ManageKpiModal from "./ManageKpiModal";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import { Kpi } from "../../../../../Types/Kpi";
import theme from "../../../../../theme";
import EditDeleteMenu from "../../../../UI/EditDeleteMenu";
import DeleteOrRemoveModal from "../../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";

const KpiContainer = styled(Box)(() => ({
  backgroundColor: theme.palette.surface.secondary.main,
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2, 2, 2, 3),
  borderRadius: theme.shape.radius.minimal,
  height: "100%",
}));

const Header = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  minHeight: "40px",
}));

const KpiContent = styled(Typography)(() => ({
  whiteSpace: "pre-line",
  paddingBlock: theme.spacing(2),
}));

const KpiTarget = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  cursor: "pointer",
}));

const KpiTargetBuy = styled(KpiTarget)(() => ({
  cursor: "default",
  "& > *": {
    color: theme.palette.text.brand.accessibility,
  },
}));

const KpiTargetPilot = styled(KpiTarget)(() => ({
  marginTop: theme.spacing(1),
  cursor: "default",
  justifyContent: "end",
  "& > span": {
    color: theme.palette.text.secondary,
  },
}));

const KpiTargetNumericResult = styled(KpiTarget, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $fulfilled }: { $fulfilled: boolean | null }) => ({
  "& > *": {
    color: $fulfilled ? theme.palette.success.main : theme.palette.error.main,
  },
  "& > svg": {
    fontSize: "1rem",
  },
}));

const KpiTargetBinaryResult = styled(KpiTarget, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $fulfilled }: { $fulfilled: boolean | null }) => ({
  display: "flex",
  alignItems: "center",
  "& > p, & > svg": {
    color: $fulfilled ? theme.palette.success.main : theme.palette.error.main,
  },
  "& > svg": {
    fontSize: "1rem",
  },
}));

const KpiTargetNotMeasuredResult = styled(KpiTarget)(() => ({
  color: theme.palette.text.disabled,
  paddingBlock: theme.spacing(1),
}));

const AddAchievedValueButton = styled(Button)(() => ({
  paddingLeft: "0",
  marginBottom: theme.spacing(2),
  color: theme.palette.action.secondary,
  textTransform: "none",
  alignSelf: "start",
}));

interface Props {
  kpi: Kpi;
  refreshObjective: (kpiId: number, isKpiFulfilled: boolean | null) => void;
  createKpi: (newKpi: Kpi) => void;
  updateKpi: (objectiveId: number, updatedKpi: Kpi) => void;
  deleteKpi: (id: number) => void;
  canEdit: boolean;
}

const KpiCard = ({ kpi, ...props }: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const [editKpiModalOpen, setEditKpiModalOpen] = useState(false);
  const [deleteKpiModalOpen, setDeleteKpiModalOpen] = useState(false);

  const isBuyStageActive = activeStep === 2;
  const isPilotStageActive = activeStep === 3;

  const hasKpiNoAchievedValue =
    isPilotStageActive && kpi.fulfilled === null && kpi.isNotMeasured === false;
  const hasKpiNumericTarget =
    isPilotStageActive && kpi.fulfilled !== null && kpi.operator !== "binary";
  const hasKpiBinaryTarget =
    isPilotStageActive && kpi.fulfilled !== null && kpi.operator === "binary";
  const hasKpiNoMeasuredValue = isPilotStageActive && kpi.isNotMeasured;

  return (
    <>
      <KpiContainer data-testid="kpi-card">
        <Header data-testid="kpi-header">
          <SpeedOutlinedIcon fontSize="small" />
          <Typography variant="subtitle2">KPI</Typography>
          {isBuyStageActive && props.canEdit && (
            <EditDeleteMenu
              entity="kpi"
              setEditModalOpen={setEditKpiModalOpen}
              setDeleteModalOpen={setDeleteKpiModalOpen}
              sx={{ ml: "auto" }}
            />
          )}
        </Header>
        <KpiContent variant="body1" data-testid="kpi-content">
          {kpi.description}
        </KpiContent>
        {isBuyStageActive && kpi.target ? (
          <KpiTargetBuy data-testid="kpi-target">
            <Typography variant="subtitle2">{kpi.operator}</Typography>
            <Typography variant="h4">{kpi.target}</Typography>
            <Typography variant="subtitle2" alignSelf="start">
              {kpi.unit?.name || ""}
            </Typography>
          </KpiTargetBuy>
        ) : hasKpiNoAchievedValue && props.canEdit ? (
          <AddAchievedValueButton onClick={() => setEditKpiModalOpen(true)}>
            <AddIcon /> Add achieved value
          </AddAchievedValueButton>
        ) : hasKpiNumericTarget ? (
          <KpiTargetNumericResult
            data-testid="kpi-target-result"
            $fulfilled={kpi.fulfilled}
            onClick={() => props.canEdit && setEditKpiModalOpen(true)}
            sx={{ cursor: props.canEdit ? "pointer" : "default" }}
          >
            {kpi.fulfilled === true ? <CheckCircleIcon /> : <CancelIcon />}
            <Typography variant="h4">{kpi.measurement}</Typography>
            <Typography alignSelf="start" variant="subtitle2">
              {kpi.unit?.name || ""}
            </Typography>
          </KpiTargetNumericResult>
        ) : hasKpiBinaryTarget ? (
          <KpiTargetBinaryResult
            data-testid="kpi-target-result"
            $fulfilled={kpi.fulfilled}
            onClick={() => props.canEdit && setEditKpiModalOpen(true)}
            sx={{ cursor: props.canEdit ? "pointer" : "default" }}
          >
            {kpi.fulfilled ? (
              <>
                <CheckCircleIcon />
                <Typography variant="body1">Fulfilled</Typography>
              </>
            ) : (
              <>
                <CancelIcon />
                <Typography variant="body1">Not Fulfilled</Typography>
              </>
            )}
          </KpiTargetBinaryResult>
        ) : hasKpiNoMeasuredValue && props.canEdit ? (
          <KpiTargetNotMeasuredResult onClick={() => setEditKpiModalOpen(true)}>
            <Typography variant="body1">No Value Available</Typography>
          </KpiTargetNotMeasuredResult>
        ) : (
          ""
        )}
        {isPilotStageActive && kpi.target && (
          <KpiTargetPilot data-testid="kpi-target">
            <Typography variant="overline">Target</Typography>
            <Typography
              variant="overline"
              bgcolor="surface.primary.main"
              p={0.5}
              borderRadius={theme.shape.radius.minimal}
            >
              {kpi.operator} {kpi.target} {kpi.unit?.name}
            </Typography>
          </KpiTargetPilot>
        )}
      </KpiContainer>
      {editKpiModalOpen && (
        <ManageKpiModal
          objectiveId={kpi.objectiveId}
          modalOpen={editKpiModalOpen}
          setModalOpen={setEditKpiModalOpen}
          kpi={kpi}
          refreshObjective={props.refreshObjective}
          updateKpi={props.updateKpi}
          createKpi={props.createKpi}
        />
      )}
      {deleteKpiModalOpen && (
        <DeleteOrRemoveModal
          id={kpi.id}
          entity="kpi"
          modalOpen={deleteKpiModalOpen}
          handleCloseModal={() => setDeleteKpiModalOpen(false)}
          handleDelete={props.deleteKpi}
          actionType="delete"
        />
      )}
    </>
  );
};

export default KpiCard;
