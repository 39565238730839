import { Box, BoxProps, styled } from "@mui/material";
import { ReactElement } from "react";

const CheckboxContainer = styled(Box)({
  font: '24px/1.4 "RobotoDraft", sans-serif',
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  userSelect: "none",

  "& input[type='checkbox']": {
    display: "none",
  },

  "& input[type='checkbox'] + label": {
    cursor: "pointer",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "#000000",
    transition: "color 250ms cubic-bezier(0.4, 0, 0.23, 1)",
    padding: "4px",
  },

  "& input[type='checkbox'] + label > span": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "18px",
    height: "18px",
    background: "transparent",
    border: "2px solid #000",
    borderRadius: "2px",
    transition: "all 250ms cubic-bezier(0.4, 0, 0.23, 1)",
  },

  "& input[type='checkbox'] + label:hover": {
    backgroundColor: "#0076a814",
  },

  "& input[type='checkbox']:checked + label > span": {
    border: "0.4em solid #0076a8",
    animation: "shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1)",
  },

  "& input[type='checkbox']:checked + label > span:before": {
    content: '""',
    position: "absolute",
    top: "0.9em",
    left: "0.6em",
    borderRight: "2px solid transparent",
    borderBottom: "2px solid transparent",
    transform: "rotate(45deg)",
    transformOrigin: "0% 100%",
    animation:
      "checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards",
  },

  "@keyframes shrink-bounce": {
    "0%": {
      transform: "scale(1)",
    },
    "33%": {
      transform: "scale(0.85)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },

  "@keyframes checkbox-check": {
    "0%": {
      width: 0,
      height: 0,
      borderColor: "#ffffff",
      transform: "translate3d(0, 0, 0) rotate(45deg)",
    },
    "33%": {
      width: "0.2em",
      height: 0,
      transform: "translate3d(0, 0, 0) rotate(45deg)",
    },
    "100%": {
      width: "0.2em",
      height: "0.5em",
      borderColor: "#ffffff",
      transform: "translate3d(0, -0.5em, 0) rotate(45deg)",
    },
  },
});

interface CustomCheckboxProps {
  id: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: BoxProps["sx"];
  "data-testid"?: string;
}

export default function CustomCheckbox({
  id,
  checked,
  onChange,
  sx,
  "data-testid": testId,
}: CustomCheckboxProps): ReactElement {
  return (
    <CheckboxContainer sx={sx}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        data-testid={testId}
      />
      <label htmlFor={id}>
        <span></span>
      </label>
    </CheckboxContainer>
  );
}
