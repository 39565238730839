import { FastForward, FastForwardOutlined } from "@mui/icons-material";
import { Box, Link, Stack, styled, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { ReactElement, useState } from "react";
import ProjectTasksHttpService from "../../Http/ProjectTasks/ProjectTasks.http.service";
import theme from "../../theme";
import { ProjectTask, ProjectTaskStatus } from "../../Types/ProjectTask";
import CustomCheckbox from "./CustomCheckbox";

const TaskCardContainer = styled(Link)({
  backgroundColor: theme.palette.surface.secondary.light,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(2, 2, 2, 3),
  borderLeft: `4px solid ${theme.palette.surface.brand.main}`,
  borderRadius: theme.shape.radius.minimal,
  transition: "all 0.3s ease",
  color: theme.palette.text.primary,
  "&:hover": {
    color: theme.palette.text.primary,
    boxShadow: theme.boxShadows[2],
    transform: "translateY(-1px)",
  },
});

const TaskName = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  ...theme.typography.subtitle2,
});

const SkipButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.action.main,
  cursor: "pointer",
  gap: 0.25,
  ":hover": {
    color: theme.palette.text.action.hover,
  },
}));

interface TaskCardProps {
  task: ProjectTask;
  onUpdateTaskStatus(): void;
}

export default function TaskCard({
  task,
  onUpdateTaskStatus,
}: TaskCardProps): ReactElement {
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [taskSkipped, setTaskSkipped] = useState(false);

  const handleDelayedTaskUpdate = (
    action: ProjectTaskStatus,
    taskToUpdate: ProjectTask
  ) => {
    setTimeout(() => {
      ProjectTasksHttpService.updateProjectTask(taskToUpdate.id, action)
        .then(onUpdateTaskStatus)
        .catch(() => {
          enqueueSnackbar("Something went wrong while updating task", {
            variant: "error",
          });
          setTaskCompleted(false);
          setTaskSkipped(false);
        });
    }, 300);
  };

  const handleCompleteTask = () => {
    setTaskCompleted(true);
    handleDelayedTaskUpdate("COMPLETED", task);
  };

  const handleSkipTask = () => {
    setTaskSkipped(true);
    handleDelayedTaskUpdate("SKIPPED", task);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      handleCompleteTask();
    }
  };

  return (
    <TaskCardContainer
      data-testid={`task-card-${task.id}`}
      href={`/projects/${task.project?.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box
        display="flex"
        gap={3}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <TaskName>{task.description}</TaskName>
        <Box display="flex" gap={1} height={24} overflow="visible">
          <SkipButton
            onClick={(e) => {
              e.preventDefault();
              handleSkipTask();
            }}
            data-testid={`skip-task-${task.id}`}
          >
            {taskSkipped ? (
              <FastForward fontSize="small" />
            ) : (
              <FastForwardOutlined fontSize="small" />
            )}
            <Typography>Skip</Typography>
          </SkipButton>
          <CustomCheckbox
            id={"checkbox-" + task.id}
            data-testid={"checkbox-" + task.id}
            checked={taskCompleted}
            onChange={handleCheckboxChange}
            sx={{ height: "fit-content", mt: -1, mr: -1 }}
          />
        </Box>
      </Box>
      <Stack gap={1}>
        <Typography variant="body2">{task.project?.name}</Typography>
        <Box
          display="flex"
          gap={1}
          color="text.mediumEmphasis"
          alignItems="baseline"
        >
          <Typography variant="subtitle2">
            {task.project?.businessUnit.ventureClient.name}
          </Typography>
          <Typography variant="body2">
            {task.project?.businessUnit.name}
          </Typography>
        </Box>
      </Stack>
    </TaskCardContainer>
  );
}
