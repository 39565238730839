import {
  Box,
  Button,
  Collapse,
  IconButton,
  Link,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Fragment,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import theme from "../../../../../theme";
import { ClientContactDTO } from "../../../../../Types/ClientContact";
import { ProductDemo } from "../../../../../Types/ProductDemo";
import { Project } from "../../../../../Types/Project";
import { StartupContactDTO } from "../../../../../Types/StartupContact";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { SelectInput } from "../../../../UI/InputFields/SelectInput";
import CustomDatePicker from "../../../../UI/InputFields/CustomDatePicker";
import CustomTextField from "../../../../UI/InputFields/CustomTextField";
import EditDeleteMenu from "../../../../UI/EditDeleteMenu";
import ClientContactSelect from "../../../../UI/InputFields/ClientContactSelect";
import StartupContactSelect from "../../../../UI/InputFields/StartupContactSelect";
import CustomExpendableText from "../../../../UI/CustomExpendableText";
import DeleteOrRemoveModal from "../../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import { findLogo } from "../../../../../utils";
import parse from "html-react-parser";
import RichTextEditor from "../../../../UI/InputFields/RichTextEditor/RichTextEditor";
import useRoles from "../../../../../Hooks/useRoles";

const StyledTableRow = styled(TableRow)(() => ({
  "&:first-of-type": {
    border: 4,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& > td": { borderBottom: "none" },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.surface.tertiary.dark,
    color: theme.palette.text.primaryInvert.main,
  },
  [`&.${tableCellClasses.body}`]: {
    borderColor: theme.palette.borderOutline.soft,
  },
}));

interface RowProps {
  index: number;
  project: Project;
  productDemo: ProductDemo;
  onEdit: (productDemo: ProductDemo) => void;
  onDelete: () => void;
  onEditClick: () => void;
  onCancelClick: () => void;
  onSaveClick: () => void;
}

function addClientContactOrderIds(
  contacts: ClientContactDTO[]
): ClientContactDTO[] {
  contacts.forEach((contact, index) => {
    if (contact.ProductDemosClientContact) {
      contact.ProductDemosClientContact.orderId = index + 1;
    } else {
      contact.ProductDemosClientContact = { orderId: index + 1 };
    }
  });
  return contacts;
}

function addStartupContactOrderIds(
  contacts: StartupContactDTO[]
): StartupContactDTO[] {
  contacts.forEach((contact, index) => {
    if (contact.ProductDemosStartupContact) {
      contact.ProductDemosStartupContact.orderId = index + 1;
    } else {
      contact.ProductDemosStartupContact = { orderId: index + 1 };
    }
  });
  return contacts;
}

export default function ProductDemosTable(props: RowProps): ReactElement {
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(props.productDemo.id === 0);
  const { globalEditMode, setGlobalEditMode } = useContext(
    GlobalProjectEditContext
  );
  const { canEdit } = useRoles(props.project);
  const prevGlobalEditModeRef = useRef(globalEditMode);

  useEffect(() => {
    if (editMode) setOpen(true);
    else setOpen(false);
  }, [editMode]);

  useEffect(() => {
    if (props.productDemo.id === 0) setGlobalEditMode(true);
  }, [props.productDemo]);

  useEffect(() => {
    if (prevGlobalEditModeRef.current !== globalEditMode && !globalEditMode) {
      setEditMode(false);
    }
    prevGlobalEditModeRef.current = globalEditMode;
  }, [globalEditMode]);

  const handleVentureClientParticipantsChange = (
    contacts: ClientContactDTO[]
  ) => {
    props.onEdit({
      ...props.productDemo,
      ventureClientParticipants: addClientContactOrderIds(contacts),
    });
  };

  const handleStartupParticipantsChange = (contacts: StartupContactDTO[]) => {
    props.onEdit({
      ...props.productDemo,
      startupParticipants: addStartupContactOrderIds(contacts),
    });
  };

  const handleStartupChange = (opportunityId: number) => {
    const startupContact = props.project.opportunities.find(
      (opp) => opp.id === opportunityId
    )?.startup.mainContact;

    props.onEdit({
      ...props.productDemo,
      startupParticipants: startupContact ? [startupContact] : [],
      opportunityId: opportunityId,
    });
  };

  const selectedStartup = props.project.opportunities.find(
    (opportunity) => opportunity.id === props.productDemo.opportunityId
  )?.startup;

  const handleEdit = () => {
    props.onEditClick();
    setEditMode(true);
  };

  const participantsCount =
    props.productDemo.ventureClientParticipants.length +
    props.productDemo.startupParticipants.length;

  const startupLogo = findLogo(selectedStartup?.files);

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Fragment key={props.index}>
      <StyledTableRow data-testid={"demo-" + props.productDemo.id}>
        <StyledTableCell sx={{ paddingRight: 0 }}>
          <IconButton
            data-testid={`expand-product-demo-button-${props.productDemo.id}`}
            aria-label="expand row"
            size="small"
            sx={{ color: theme.palette.icon.primary, padding: 0 }}
            disabled={editMode}
            onClick={toggleOpen}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>
          <Link
            href={`/startups/${selectedStartup?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            data-testid={`startup-link-${selectedStartup?.id}`}
            sx={{
              display: "flex",
              gap: theme.spacing(2),
              alignItems: "center",
              color: theme.palette.text.primary,
              maxWidth: editMode ? undefined : "fit-content",
            }}
          >
            {!editMode && startupLogo && (
              <Box
                component="img"
                src={startupLogo}
                sx={{
                  width: theme.spacing(10),
                  height: theme.spacing(8),
                  objectFit: "contain",
                }}
              />
            )}
            <SelectInput
              fullWidth
              id={"startup-id-" + props.productDemo.id}
              data-testid={"startup-id-" + props.productDemo.id}
              label={editMode ? "Startup" : undefined}
              selectValues={
                props.project.opportunities
                  ?.filter((opportunity) => opportunity.isQualified)
                  .map((opportunity) => {
                    return {
                      id: opportunity.id,
                      name: opportunity.startup.name,
                    };
                  }) || []
              }
              value={props.productDemo.opportunityId}
              editMode={editMode}
              onChange={(e) => handleStartupChange(parseInt(e.target.value))}
            />
          </Link>
        </StyledTableCell>
        <StyledTableCell>
          <CustomDatePicker
            id={"date-" + props.productDemo.id}
            data-testid={"date-" + props.productDemo.id}
            label={editMode ? "Date" : undefined}
            value={
              props.productDemo.date ? new Date(props.productDemo.date) : null
            }
            fullWidth
            editMode={editMode}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any) => {
              if (!e?.$d) return;
              props.onEdit({
                ...props.productDemo,
                date: e.$d || null,
              });
            }}
            renderInputProps={{
              sx: {
                maxWidth: theme.spacing(20),
              },
              "data-testid": "date-" + props.productDemo.id,
            }}
          />
        </StyledTableCell>
        <StyledTableCell>
          <CustomTextField
            id={"participants-" + props.productDemo.id}
            value={participantsCount}
            editMode={false}
            fullWidth
          />
        </StyledTableCell>
        {canEdit && (
          <StyledTableCell>
            {editMode ? (
              <Box display="flex" gap={2}>
                <Button
                  variant="contained"
                  sx={{ minWidth: theme.spacing(10) }}
                  onClick={() => {
                    props.onSaveClick();
                    setEditMode(false);
                  }}
                  data-testid="demos-save-button"
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    props.onCancelClick();
                    setEditMode(false);
                  }}
                  data-testid="demos-cancel-button"
                  size="small"
                >
                  Cancel
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <EditDeleteMenu
                  entity="product-demo"
                  setEditModalOpen={handleEdit}
                  setDeleteModalOpen={setDeleteModalOpen}
                  sx={{ fontSize: 18 }}
                  disabled={globalEditMode}
                />
              </Box>
            )}
          </StyledTableCell>
        )}
      </StyledTableRow>
      <StyledTableRow
        sx={{
          "& .MuiTableCell-root": {
            ...(!open && {
              paddingY: 0,
            }),
          },
        }}
      >
        <StyledTableCell>
          <Collapse in={open} timeout="auto" unmountOnExit />
        </StyledTableCell>
        <StyledTableCell>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ClientContactSelect
              labelText="Venture Client Participants"
              ventureClientId={props.project.businessUnit?.ventureClient.id}
              onChange={handleVentureClientParticipantsChange}
              fieldId="ventureClientParticipants"
              editMode={editMode}
              multiSelect={true}
              contactData={props.productDemo.ventureClientParticipants}
            />
          </Collapse>
        </StyledTableCell>
        <StyledTableCell colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <StartupContactSelect
              editMode={editMode}
              labelText="Startup Participants"
              startupId={
                props.project.opportunities?.find(
                  (opportunity) =>
                    opportunity.id == props.productDemo.opportunityId
                )?.startupId
              }
              onChange={handleStartupParticipantsChange}
              contactData={props.productDemo.startupParticipants}
              fieldId="startupParticipants"
              multiSelect={true}
            />
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow
        sx={{
          "& .MuiTableCell-root": {
            ...(!open && {
              paddingY: 0,
            }),
          },
        }}
      >
        <StyledTableCell>
          <Collapse in={open} timeout="auto" unmountOnExit />
        </StyledTableCell>
        <StyledTableCell colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {editMode ? (
              <Box height="157px">
                <RichTextEditor
                  fieldId="startupQuestions"
                  labelText="Specific Questions for This Startup"
                  editMode={editMode}
                  fieldValue={props.productDemo.questions as string}
                  onChange={(value) =>
                    props.onEdit({
                      ...props.productDemo,
                      questions: value,
                    })
                  }
                  maxCharacter={600}
                  data-testid="startup-questions"
                  isListsDisabled
                />
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                gap={theme.spacing(0.5)}
                padding={2}
              >
                <Typography variant="caption" color="text.mediumEmphasis">
                  Specific Questions for This Startup
                </Typography>
                <Box sx={{ ...theme.typography.body1 }}>
                  <CustomExpendableText
                    maxLines={3}
                    text={parse(props.productDemo.questions || "--")}
                  />
                </Box>
              </Box>
            )}
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
      {deleteModalOpen && (
        <DeleteOrRemoveModal
          id={props.productDemo.id}
          modalOpen={deleteModalOpen}
          handleCloseModal={() => setDeleteModalOpen(false)}
          entity="Startup Demo"
          handleDelete={() => props.onDelete()}
          actionType="delete"
        />
      )}
    </Fragment>
  );
}
export const exportedForTesting = {
  addClientContactOrderIds,
  addStartupContactOrderIds,
};
