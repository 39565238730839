import { Box, Typography, styled } from "@mui/material";
import theme from "../../../theme";
import { ReactElement, useState } from "react";
import { ContentCopy, MailOutline, PhonelinkRing } from "@mui/icons-material";
import { useSnackbar } from "notistack";

const ContactCopyWrapper = styled(Box)(() => ({
  display: "flex",
  width: "fit-content",
  minWidth: 96,
  gap: theme.spacing(1),
  color: theme.palette.text.action.main,
  alignItems: "center",
  fontSize: theme.spacing(2),
}));

interface ContactCopyProps {
  value?: string;
  variant: "phone" | "email";
}

const ContactCopy = ({ value, variant }: ContactCopyProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [hovering, setHovering] = useState(false);
  const icon =
    variant === "phone" ? (
      <PhonelinkRing fontSize="inherit" />
    ) : (
      <MailOutline fontSize="inherit" />
    );

  const handleMouseEnter = () => {
    if (value) setHovering(true);
  };

  const handleMouseLeave = () => {
    if (value) setHovering(false);
  };

  const handleClick = () => {
    if (value) {
      navigator.clipboard.writeText(value);
      const entity = variant === "phone" ? "Phone" : "Email address";
      enqueueSnackbar(entity + " successfully copied to clipboard", {
        variant: "success",
      });
    }
  };

  return (
    <ContactCopyWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      sx={{ cursor: value ? "pointer" : "auto" }}
    >
      {hovering ? (
        <ContentCopy fontSize="inherit" />
      ) : (
        <Box display="flex" color="icon.primary">
          {icon}
        </Box>
      )}
      <Typography
        variant="body2"
        color={value ? "inherit" : "text.primary"}
        overflow="hidden"
        textOverflow="ellipsis"
        noWrap
      >
        {value ? value : "--"}
      </Typography>
    </ContactCopyWrapper>
  );
};

export default ContactCopy;
