import { EventAvailableOutlined } from "@mui/icons-material";
import { Paper, Stack, styled, Typography } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../theme";

const NoTasksCardContainer = styled(Paper)({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2, 2, 2, 3),
  borderRadius: theme.shape.radius.minimal,
  gap: theme.spacing(1),
});

export default function NoTasksCard(): ReactElement {
  return (
    <NoTasksCardContainer data-testid="no-tasks-card">
      <EventAvailableOutlined htmlColor={theme.palette.icon.disabled} />
      <Stack>
        <Typography variant="subtitle1">{"You're all caught up!"}</Typography>
        <Typography variant="body2" color="text.mediumEmphasis">
          There are currently no open tasks for you.
        </Typography>
      </Stack>
    </NoTasksCardContainer>
  );
}
