import { ReactElement } from "react";
import CustomMenu from "../../../../UI/CustomMenu";

interface Props {
  isArchived: boolean;
  onArchive?(): void;
  onUnarchive?(): void;
  openDeleteModal(): void;
}

const ArchiveDeleteMenu = ({
  isArchived,
  onArchive,
  onUnarchive,
  openDeleteModal,
}: Props): ReactElement => {
  // Dynamically determine archive/unarchive action
  const archiveActionLabel = isArchived ? "Unarchive" : "Archive";
  const archiveActionFunction = isArchived ? onUnarchive : onArchive;

  const actions = [
    ...(archiveActionFunction
      ? [
          {
            label: archiveActionLabel,
            action: archiveActionFunction,
          },
        ]
      : []),
    {
      label: "Delete",
      action: openDeleteModal,
    },
  ];

  return <CustomMenu entity="archive-delete" actions={actions} />;
};

export default ArchiveDeleteMenu;
