import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import { LeadProject } from "../../Types/LeadProject";
import { Fragment, ReactElement, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSnackbar } from "notistack";
import { LeadProjectsHttpService } from "../../Http/LeadProjects/LeadProjects.http.service";
import { useHistory } from "react-router-dom";
import EditLeadProjectStatus from "./LeadProjectStatus/EditLeadProjectStatus";

interface DeleteLoadProjectModalProps {
  handleModalClose: () => void;
  modalOpen: boolean;
  leadProject: LeadProject;
  handleSave: () => void;
}

export default function DeleteLoadProjectModal(
  props: DeleteLoadProjectModalProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [openLeadArchiveModal, setOpenLeadArchiveModal] = useState(false);
  const [associatedModal, setAssociatedModal] = useState(false);
  const isLeadProjectArhived = props.leadProject.status == "archived";

  const handleEditStatus = () => {
    setOpenLeadArchiveModal(true);
    setAssociatedModal(false);
    props.handleModalClose();
  };

  const handleDeleteLeadProject = async () => {
    try {
      await LeadProjectsHttpService.deleteLeadProject(props.leadProject.id);
      enqueueSnackbar(
        <span>
          The lead <strong>{props.leadProject.name}</strong> has been
          successfully <strong>deleted</strong>.
        </span>,
        { variant: "success" }
      );

      history.push("/projects");
    } catch (error) {
      enqueueSnackbar("Failed to delete the lead. Please try again.", {
        variant: "error",
      });
    } finally {
      props.handleModalClose();
    }
  };

  return (
    <Fragment>
      <Dialog
        maxWidth="sm"
        open={props.modalOpen}
        onClose={props.handleModalClose}
        data-testid="launch-project-modal"
        sx={{
          ".MuiPaper-root": {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle sx={{ pb: 0 }}>
          Are you sure you want to delete this lead?
        </DialogTitle>

        <Stack gap={2} px={4}>
          <Stack gap={2}>
            <Typography>
              You are about to permanently delete the following lead:
            </Typography>
            <Typography fontWeight="bold">{props.leadProject.name}</Typography>
            <Typography>
              This action can&apos;t be undone, and all related data will be
              lost.
            </Typography>
          </Stack>

          <Box
            display="flex"
            justifyContent="center"
            p={2}
            bgcolor={theme.palette.surface.lightEmphasis}
            borderRadius={theme.shape.radius.cardSmall}
          >
            <Box display="flex" gap={2}>
              <InfoOutlinedIcon color="secondary" />
              <Box display="flex" flexDirection="column" gap={1}>
                {isLeadProjectArhived ? (
                  <Typography fontWeight="bold">
                    Keep Archived to Preserve Data
                  </Typography>
                ) : (
                  <Typography fontWeight="bold">
                    Consider Archiving to Keep the Data
                  </Typography>
                )}
                {isLeadProjectArhived ? (
                  <Typography>
                    If you’d prefer to keep the data for future reference or
                    analysis, leave it archived.
                  </Typography>
                ) : (
                  <Typography>
                    If you’d prefer to keep the data for future reference or
                    analysis, you can archive this lead instead.
                  </Typography>
                )}

                {!isLeadProjectArhived && (
                  <Typography
                    color={theme.palette.text.action.main}
                    fontWeight="bold"
                    onClick={handleEditStatus}
                    sx={{ cursor: "pointer" }}
                  >
                    Archive Lead
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Button onClick={props.handleModalClose}>Cancel</Button>
            <Button
              color="error"
              variant="contained"
              onClick={handleDeleteLeadProject}
            >
              Delete Lead
            </Button>
          </Box>
        </Stack>
      </Dialog>
      {openLeadArchiveModal && (
        <EditLeadProjectStatus
          leadProjectId={props.leadProject.id}
          status="archived"
          modalOpen={openLeadArchiveModal}
          setModalOpen={(state) => {
            setOpenLeadArchiveModal(state);
            if (!state) props.handleModalClose();
          }}
          handleSave={props.handleSave}
          openAssociatedModal={associatedModal}
          setLeadProjectStatusOverviewModal={setAssociatedModal}
          preventStatusChange
        />
      )}
    </Fragment>
  );
}
