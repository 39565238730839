import { Accordion, AccordionDetails } from "@mui/material";
import { Stack } from "@mui/system";
import { ReactElement, useEffect, useState } from "react";
import { OriginsByLeadInitiative } from "../../../../../Constants/LeadInitiatives";
import CustomAccordionSummary from "../../../../UI/CustomAccordionSummary";
import { InitiativeName } from "../../../../../Types/InitiativeName";
import { InitiativeNamesHttpService } from "../../../../../Http/Initiatives/InitiativeNames.http.service";
import { enqueueSnackbar } from "notistack";
import LeadInitiative from "./LeadInitiative";
import {
  InitiativeType,
  LeadProjectOriginType,
} from "../../../../../Types/LeadProject";

interface CompanyLeadInitiativesProps {
  ventureClientId: number;
}

export default function CompanyLeadInitiatives({
  ventureClientId,
}: CompanyLeadInitiativesProps): ReactElement {
  const [initiativeNames, setInitiativeNames] = useState<InitiativeName[]>();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    InitiativeNamesHttpService.getInitiativeNames(
      ventureClientId,
      undefined,
      true
    )
      .then((data) => {
        setInitiativeNames(data);
      })
      .catch(() => {
        enqueueSnackbar("Error fetching Initiative Names", {
          variant: "error",
        });
      });
  }, [refresh]);

  return (
    <div>
      <Accordion defaultExpanded>
        <CustomAccordionSummary>Lead Initiatives</CustomAccordionSummary>
        <AccordionDetails>
          <Stack gap={1}>
            {Object.entries(OriginsByLeadInitiative)
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([initiative, origins]) => (
                <LeadInitiative
                  key={initiative}
                  ventureClientId={ventureClientId}
                  name={initiative as InitiativeType}
                  origins={origins as LeadProjectOriginType[]}
                  initiativeNames={
                    initiativeNames?.filter(
                      (initiativeName) =>
                        initiativeName.initiativeType === initiative
                    ) || []
                  }
                  handleRefresh={() => {
                    setRefresh(!refresh);
                  }}
                />
              ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
