import { Link as RouterLink } from "react-router-dom";
import {
  TableRow,
  TableCell,
  styled,
  Link,
  IconButton,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import LoupeOutlinedIcon from "@mui/icons-material/LoupeOutlined";
import OpportunityRating from "../OpportunityRating/OpportunityRating";
import { SolutionCluster } from "../../../../../../Types/SolutionCluster";
import { Requirement } from "../../../../../../Types/Requirement";
import { ReactElement, useContext, useState } from "react";
import {
  Opportunity,
  OpportunityRating as OpportunityRatingType,
  RejectionReason,
} from "../../../../../../Types/Opportunity";
import AssignCluster from "../AssignCluster/AssignCluster";
import { DetailsViewStartupCells } from "../DetailsView/DetailsView";
import RequirementComments from "../RequirementsView/RequirementsComments/RequirementsComments";
import { getDateMonthsAfter, normalizeUrl } from "../../../../../../utils";
import HighlightStartup from "../HighlightStartup/HighlightStartup";
import { Flipped } from "react-flip-toolkit";
import RecommendStartup from "../RecommendStartup/RecommendStartup";
import { RequirementsStartupCells } from "../RequirementsView/RequirementsStartupCells";
import { SelectionRationale } from "../SelectionRationale/SelectionRationale";
import { FunnelStage, Project } from "../../../../../../Types/Project";
import { Comment, RocketLaunchOutlined } from "@mui/icons-material";
import DeleteOrRemoveModal from "../../../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import OpportunityHttpService from "../../../../../../Http/Opportunity/Opportunity.Http.service";
import theme from "../../../../../../theme";
import QualityVerificationIcon from "../../../../../Startup/DetailsPage/QualityVerification/QualityVerificationIcon";
import CloseIcon from "@mui/icons-material/Close";
import TopContenderBadge from "./TopContenderBadge";
import StartupQuickOverviewModal from "../../../../../UI/Modals/QuickOverviewModal/StartupQuickOverviewModal";
import SkeletonWrapper from "../../../../../UI/SkeletonWrapper";
import { OpportunitiesLoadingContext } from "../../../../../../Context/OpportunitiesLoaderContext";
import useRoles from "../../../../../../Hooks/useRoles";

export const StyledTableRow = styled(TableRow)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const TruncatedTableCell = styled(TableCell)(({ theme }) => ({
  "& .truncated-link": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  "& button": {
    "&.delete-opportunity": {
      opacity: 0,
      maxWidth: 0,
      padding: 0,
      overflow: "hidden",
      transition: "opacity 0s, max-width 0.3s;",
    },
    cursor: "pointer",
    color: theme.palette.common.white,
  },
  "&:hover button": {
    "&.delete-opportunity": {
      opacity: 1,
      maxWidth: "24px",
      padding: theme.spacing(1),
      transition: "opacity 0s, max-width 0.3s, padding 0.3s;",
    },
  },
}));

interface SolutionFitMatrixOpportunityProps {
  opportunity: Opportunity;
  opportunityLogo: string | null;
  detailsView: boolean;
  clusters: SolutionCluster[];
  requirements: Requirement[];
  expandedCommentsOpportunity: number | null;
  ventureClientId: number;
  toggleComment: () => void;
  handleRequirementCommentChange: (
    selectedOpportunity: Opportunity,
    updatedRequirementId: number,
    updatedComment: string
  ) => void;
  handleRatingChange: (option: OpportunityRatingType) => void;
  handleChangeCluster: (cluster: number) => void;
  handleChangeRecommended: (recommended: boolean) => void;
  handleRequirementChange: (
    updatedRequirementId: number,
    updatedStatus: string
  ) => void;
  handleInsightsUpdate: (updatedInsights: string) => void;
  editingOpportunity: string | null;
  setEditingOpportunity: (id: string | null) => void;
  handleSave: () => void;
  isAddingStartup: boolean;
  projectFunnelStage: FunnelStage;
  currentProjectStage: FunnelStage;
  handleOpportunityStatusChange: (opportunity: Opportunity) => void;
  highlightedOpportunities: number[];
  opportunityList: Opportunity[];
  isSelectionReasonVisible: boolean;
  handleRejectionReasonChange: (
    updatedRejectionReasons: RejectionReason[],
    getUpdatedReasons: string
  ) => void;
  isTopContender?: boolean;
  project: Project;
}

export default function SolutionFitMatrixOpportunity(
  props: SolutionFitMatrixOpportunityProps
): ReactElement {
  const { canEdit } = useRoles(props.project);
  const { opportunitiesLoading } = useContext(OpportunitiesLoadingContext);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [currentOpportunityIndex, setCurrentOpportunityIndex] = useState<
    number | null
  >(null);

  const handleOpenQuickOverview = (index: number) => {
    setCurrentOpportunityIndex(index);
  };

  const handleCloseQuickOverview = () => {
    setCurrentOpportunityIndex(null);
  };

  const handleNavigateOpportunity = (direction: "next" | "prev") => {
    if (currentOpportunityIndex === null) return;

    const newIndex =
      direction === "next"
        ? (currentOpportunityIndex + 1) % props.opportunityList.length
        : (currentOpportunityIndex - 1 + props.opportunityList.length) %
          props.opportunityList.length;

    setCurrentOpportunityIndex(newIndex);
  };

  const onDelete = async (id: number) => {
    await OpportunityHttpService.deleteOpportunity(id);
    props.handleSave();
  };

  const isRemovable = !props.opportunity.isSelectedForPilot;

  let isSelectionDisabled = false;
  if (!["assess", "discover"].includes(props.currentProjectStage) || !canEdit) {
    isSelectionDisabled = true;
  }

  const showSelection = props.projectFunnelStage === "discover" ? true : false;

  const isAssessStage = props.projectFunnelStage === "assess";
  const isQualityChecked = props.opportunity.startup?.isQualityChecked;
  const expiryDate = props.opportunity.startup?.lastQualityCheckDate
    ? getDateMonthsAfter(props.opportunity.startup?.lastQualityCheckDate, 12)
    : new Date();
  const hasExpired =
    (props.opportunity.startup?.lastQualityCheckDate &&
      isQualityChecked &&
      expiryDate < new Date()) ??
    false;

  const currentOpportunity =
    props.opportunityList[currentOpportunityIndex as number];

  const strategicImage = currentOpportunity?.startup.files.find(
    (file) => file.type === "Strategic Image"
  )?.url;

  const logoImage = currentOpportunity?.startup.files.find(
    (file) => file.type === "Logo"
  )?.url;

  return (
    <Flipped
      delayUntil="add-startup-button"
      key={props.opportunity.id}
      flipId={props.opportunity.id}
    >
      {(flippedProps) => (
        <>
          <StyledTableRow
            {...flippedProps}
            sx={{ cursor: props.detailsView ? "auto" : "pointer" }}
            data-testid={`details-table-startup-${props.opportunity.id}`}
            className={`opportunity-column ${
              props.detailsView ? "details" : "requirements"
            } ${
              props.expandedCommentsOpportunity === props.opportunity.id &&
              !props.detailsView
                ? "opened-comments"
                : ""
            }
        ${
          props.highlightedOpportunities.includes(props.opportunity.id)
            ? "highlighted-selection"
            : ""
        } 
        ${isAssessStage ? "selected-for-pilot" : ""}`}
            onClick={() => {
              !props.detailsView && !props.isAddingStartup
                ? props.toggleComment()
                : null;
            }}
          >
            <TruncatedTableCell
              className="startup-name"
              key={`startup-name-cell-${props.opportunity.id}`}
              data-testid="startup-name"
            >
              {props.projectFunnelStage === "discover" &&
                !opportunitiesLoading && (
                  <HighlightStartup
                    opportunity={props.opportunity}
                    handleOpportunityStatusChange={() =>
                      props.handleOpportunityStatusChange(props.opportunity)
                    }
                    isSelectionDisabled={isSelectionDisabled}
                    checked={props.opportunity.isQualified}
                  />
                )}
              <SkeletonWrapper isLoading={opportunitiesLoading}>
                <RouterLink
                  className="truncated-link"
                  onClick={(event) => event.stopPropagation()}
                  target="_blank"
                  to={"/startups/" + props.opportunity.startup.id}
                >
                  <Typography variant="subtitle2">
                    {props.opportunity.startup.name}
                  </Typography>
                </RouterLink>
              </SkeletonWrapper>
              <Box display="flex" ml="auto" alignItems="center" gap={1}>
                {props.isTopContender &&
                  !isAssessStage &&
                  !opportunitiesLoading && <TopContenderBadge />}

                {props.detailsView &&
                  !isAssessStage &&
                  !opportunitiesLoading &&
                  canEdit && (
                    <IconButton
                      className="delete-opportunity"
                      disabled={!isRemovable}
                      data-testid={`delete-opportunity-${props.opportunity.id}`}
                      onClick={() => {
                        if (!props.isAddingStartup) {
                          setDeleteModalOpen(true);
                        }
                      }}
                      sx={{
                        ":disabled": {
                          color: theme.palette.text.primaryInvert.disabled,
                        },
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                {props.expandedCommentsOpportunity !== props.opportunity.id &&
                  !props.detailsView && (
                    <IconButton
                      className={
                        props.isTopContender ? "delete-opportunity" : ""
                      }
                      data-testid={`delete-opportunity-${props.opportunity.id}`}
                      onClick={() => {
                        if (!props.isAddingStartup) {
                          props.toggleComment();
                        }
                      }}
                    >
                      <Comment />
                    </IconButton>
                  )}
              </Box>
            </TruncatedTableCell>
            <TableCell
              className="startup-link-logo"
              data-testid="startup-link"
              sx={{
                justifyContent: "space-between",
              }}
            >
              <SkeletonWrapper
                isLoading={opportunitiesLoading}
                height={40}
                width={100}
              >
                <RouterLink
                  onClick={(event) => event.stopPropagation()}
                  target="_blank"
                  to={"/startups/" + props.opportunity.startup.id}
                >
                  {props.opportunityLogo ? (
                    <img
                      src={props.opportunityLogo}
                      alt="logo associated to the startup"
                    />
                  ) : (
                    <RocketLaunchOutlined color="disabled" />
                  )}
                </RouterLink>
              </SkeletonWrapper>
              <Box display="flex" gap={theme.spacing(1)} alignItems="center">
                {!opportunitiesLoading && (
                  <IconButton
                    onClick={() => {
                      const index = props.opportunityList.findIndex(
                        (op) => op.id === props.opportunity.id
                      );
                      handleOpenQuickOverview(index);
                    }}
                    sx={{ mb: 0.5 }}
                  >
                    <Tooltip title="Open Focus View">
                      <LoupeOutlinedIcon
                        data-testid="open-focus-view-btn"
                        htmlColor={theme.palette.icon.action.main}
                        sx={{ fontSize: "20px" }}
                      />
                    </Tooltip>
                  </IconButton>
                )}
                {props.opportunity.startup.website && (
                  <SkeletonWrapper isLoading={opportunitiesLoading}>
                    <Link
                      onClick={(event) => event.stopPropagation()}
                      target="_blank"
                      href={normalizeUrl(props.opportunity.startup.website)}
                    >
                      <LaunchIcon
                        data-testid="startup-link-icon"
                        htmlColor={theme.palette.icon.action.main}
                        sx={{ fontSize: "20px" }}
                      />
                    </Link>
                  </SkeletonWrapper>
                )}
              </Box>
            </TableCell>

            <TableCell className="rating-cell">
              <SkeletonWrapper isLoading={opportunitiesLoading} sx={{ ml: 2 }}>
                <OpportunityRating
                  opportunity={props.opportunity}
                  setEditingOpportunity={props.setEditingOpportunity}
                  editingOpportunity={props.editingOpportunity}
                  onChangeRating={(option) => {
                    props.handleRatingChange(option);
                  }}
                  isAddingStartup={props.isAddingStartup}
                  project={props.project}
                />
              </SkeletonWrapper>
            </TableCell>
            <TableCell
              className={`assign-cluster ${
                isAssessStage ? "" : "highlighted-border"
              }`}
            >
              <SkeletonWrapper
                isLoading={opportunitiesLoading}
                sx={{ ml: 2, height: "20px" }}
              >
                <AssignCluster
                  onChangeCluster={(cluster) => {
                    props.handleChangeCluster(cluster);
                  }}
                  setEditingOpportunity={props.setEditingOpportunity}
                  editingOpportunity={props.editingOpportunity}
                  clusters={props.clusters}
                  opportunity={props.opportunity}
                  isAddingStartup={props.isAddingStartup}
                  project={props.project}
                />
              </SkeletonWrapper>
            </TableCell>
            <TableCell
              sx={{
                justifyContent: "center",
              }}
            >
              <SkeletonWrapper
                isLoading={opportunitiesLoading}
                sx={{
                  minWidth: "20px",
                  minHeight: "20px",
                  borderRadius: "100%",
                }}
              >
                <QualityVerificationIcon
                  lastQualityCheckBy={
                    props.opportunity.startup.lastQualityCheckBy
                  }
                  lastQualityCheckDate={
                    props.opportunity.startup.lastQualityCheckDate
                  }
                  isQualityChecked={isQualityChecked}
                  failedQualityChecks={props.opportunity.startup.qualityChecks?.filter(
                    (qc) => qc.status === false
                  )}
                  hasExpired={hasExpired}
                />
              </SkeletonWrapper>
            </TableCell>
            {isAssessStage && (
              <TableCell className="assign-cluster highlighted-border">
                <SkeletonWrapper isLoading={opportunitiesLoading}>
                  <RecommendStartup
                    onChangeRecommended={props.handleChangeRecommended}
                    opportunity={props.opportunity}
                    isAddingStartup={props.isAddingStartup}
                    project={props.project}
                  />
                </SkeletonWrapper>
              </TableCell>
            )}
            {props.detailsView ? (
              <DetailsViewStartupCells opportunity={props.opportunity} />
            ) : (
              <SkeletonWrapper isLoading={opportunitiesLoading}>
                <RequirementsStartupCells
                  setEditingOpportunity={props.setEditingOpportunity}
                  editingOpportunity={props.editingOpportunity}
                  opportunity={props.opportunity}
                  requirements={props.requirements}
                  requirementChange={props.handleRequirementChange}
                  isSelectionReasonVisible={props.isSelectionReasonVisible}
                  project={props.project}
                />
              </SkeletonWrapper>
            )}
            {props.isSelectionReasonVisible && (
              <SelectionRationale
                opportunity={props.opportunity}
                ventureClientId={props.ventureClientId}
                handleRejectionReasonChange={props.handleRejectionReasonChange}
                project={props.project}
              />
            )}
          </StyledTableRow>
          {!props.detailsView && (
            <SkeletonWrapper isLoading={opportunitiesLoading}>
              <RequirementComments
                isSelectionReasonVisible={props.isSelectionReasonVisible}
                requirements={props.requirements}
                expandedComments={props.expandedCommentsOpportunity}
                commentChange={props.handleRequirementCommentChange}
                opportunity={props.opportunity}
                toggleComment={() => {
                  if (!props.isAddingStartup) {
                    props.toggleComment();
                  }
                }}
                handleInsightsUpdate={(updatedInsights: string) =>
                  props.handleInsightsUpdate(updatedInsights)
                }
                funnelStage={props.projectFunnelStage}
                project={props.project}
              />
            </SkeletonWrapper>
          )}
          {deleteModalOpen && (
            <DeleteOrRemoveModal
              id={props.opportunity.id}
              modalOpen={deleteModalOpen}
              handleCloseModal={() => setDeleteModalOpen(false)}
              entity="startup"
              handleDelete={onDelete}
              actionType="remove"
            />
          )}
          {currentOpportunityIndex !== null && (
            <StartupQuickOverviewModal
              handleModalClose={handleCloseQuickOverview}
              opportunity={props.opportunityList[currentOpportunityIndex]}
              opportunityLogo={logoImage}
              opportunityList={props.opportunityList}
              handleOpportunityStatusChange={
                props.handleOpportunityStatusChange
              }
              strategicImage={strategicImage}
              viewedFunnelStage={props.projectFunnelStage}
              isSelectionDisabled={isSelectionDisabled}
              showSelection={showSelection}
              handleNavigateOpportunity={handleNavigateOpportunity}
              requirements={props.requirements}
            />
          )}
        </>
      )}
    </Flipped>
  );
}
