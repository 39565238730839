import { IconButtonProps } from "@mui/material";
import { ReactElement } from "react";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import CustomMenu from "./CustomMenu";

type Props = IconButtonProps & {
  entity:
    | "objective"
    | "kpi"
    | "cluster"
    | "meeting"
    | "variable"
    | "lead-project-leader"
    | "project-status-comment"
    | "lead-status-comment"
    | "product-demo"
    | "organizational-unit";

  setEditModalOpen: (state: boolean) => void;
  setDeleteModalOpen: (state: boolean) => void;
};

const EditDeleteMenu = (props: Props): ReactElement => {
  const { entity, setEditModalOpen, setDeleteModalOpen, ...defaultProps } =
    props;

  const actions = [
    {
      label: "Edit",
      action: () => setEditModalOpen(true),
      icon: <EditOutlined sx={{ fontSize: "16px" }} />,
    },
    {
      label: "Delete",
      action: () => setDeleteModalOpen(true),
      icon: <DeleteOutlined sx={{ fontSize: "16px" }} />,
    },
  ];

  return <CustomMenu entity={entity} actions={actions} {...defaultProps} />;
};

export default EditDeleteMenu;
