import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  styled,
} from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../../../theme";
import { Opportunity } from "../../../../../Types/Opportunity";
import OpportunityHttpService from "../../../../../Http/Opportunity/Opportunity.Http.service";
import { useSnackbar } from "notistack";
import CustomAccordionSummary from "../../../../UI/CustomAccordionSummary";
import AssessmentReport from "./AssessmentReport";
import { Project } from "../../../../../Types/Project";
import AssessmentConclusionRow, {
  StyledTableRow,
} from "./AssessmentConclusionRow";
import StartupQuickOverviewModal from "../../../../UI/Modals/QuickOverviewModal/StartupQuickOverviewModal";
import { funnelStages } from "../../../../../Constants/FunnelStages";
import useRoles from "../../../../../Hooks/useRoles";
import { stageScores } from "../../../../../Constants/FunnelStages";

const SectionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  width: "100%",
}));

const SelectedStartupContainer = styled(Box)(() => ({
  display: "flex",
  alignSelf: "center",
  gap: "10px",
}));

const StyledTable = styled(Table, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isEmpty = false }: { $isEmpty?: boolean }) => ({
  display: "flex",
  width: $isEmpty ? "100%" : "fit-content",
}));

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isSelectedForPilot = false }: { $isSelectedForPilot?: boolean }) => ({
  border: 0,
  borderBottom: `1px solid ${theme.palette.borderOutline.soft}`,
  display: "block",
  borderRight: `1px solid ${theme.palette.borderOutline.soft}`,
  "&:first-of-type": {
    display: "flex",
    gap: theme.spacing(2),
    background: $isSelectedForPilot
      ? theme.palette.surface.action.main
      : theme.palette.surface.tertiary.dark,
    borderRight: `1px solid ${theme.palette.text.primaryInvert.main}`,
    color: theme.palette.text.primaryInvert.main,
    height: theme.spacing(7),
    ...theme.typography.subtitle2,
  },
}));

const EmptyTableCell = styled(StyledTableCell)(() => ({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.text.disabled,
  ...theme.typography.body1,
}));

const StickyRow = styled(StyledTableRow)(() => ({
  position: "sticky",
  background: theme.palette.surface.primary.main,
  left: 0,
  zIndex: 10,
}));

interface AssessmentConclusionProps extends Omit<AccordionProps, "children"> {
  project: Project;
  opportunities: Opportunity[];
  assessmentSummary: string | null;
  handleSave: () => void;
}

export default function AssessmentConclusion(
  props: AssessmentConclusionProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { canEdit } = useRoles(props.project);
  const [currentOpportunityIndex, setCurrentOpportunityIndex] = useState<
    number | null
  >(null);
  const qualifiedOpportunities = props.opportunities.filter(
    (opp) => opp.isQualified
  );
  const opportunitiesSelectedForPilot = qualifiedOpportunities.filter(
    (opportunity) => opportunity.isSelectedForPilot
  );
  const selectedStartupLabel = (() => {
    if (opportunitiesSelectedForPilot.length > 1) {
      return opportunitiesSelectedForPilot.length;
    }
    const opportunitySelectedForPilot = opportunitiesSelectedForPilot[0];
    return opportunitySelectedForPilot?.startup.name || "--";
  })();

  const isTableEmpty = qualifiedOpportunities.length < 1;

  const handleOpportunityStatusChange = async (
    selectedOpportunity: Opportunity
  ) => {
    try {
      await OpportunityHttpService.updateOpportunity(
        {
          isSelectedForPilot: !selectedOpportunity.isSelectedForPilot,
        },
        selectedOpportunity.id
      );
    } catch (error) {
      enqueueSnackbar("Something went wrong while updating opportunity", {
        variant: "error",
      });
    } finally {
      props.handleSave();
    }
  };

  const handleUpdateDecisionRationale = async (
    opportunityId: number,
    assessmentDecision: string
  ) => {
    try {
      await OpportunityHttpService.updateOpportunity(
        {
          assessmentDecision,
        },
        opportunityId
      );
    } catch (error) {
      enqueueSnackbar("Something went wrong while updating opportunity", {
        variant: "error",
      });
    } finally {
      props.handleSave();
    }
  };

  const handleNavigateOpportunity = (direction: "next" | "prev") => {
    if (currentOpportunityIndex === null) return;

    const newIndex =
      direction === "next"
        ? (currentOpportunityIndex + 1) % qualifiedOpportunities.length
        : (currentOpportunityIndex - 1 + qualifiedOpportunities.length) %
          qualifiedOpportunities.length;

    setCurrentOpportunityIndex(newIndex);
  };

  const handleCloseQuickOverview = () => {
    setCurrentOpportunityIndex(null);
  };

  const selectedStartupsText = `Startup${
    opportunitiesSelectedForPilot.length > 1 ? "s" : ""
  } Selected for Pilot`;

  const isSelectionDisabled =
    stageScores[props.project.funnelStage] > stageScores["assess"];

  const currentOpportunity =
    qualifiedOpportunities[currentOpportunityIndex as number];

  const logoImage = currentOpportunity?.startup.files.find(
    (file) => file.type === "Logo"
  )?.url;

  const strategicImage = currentOpportunity?.startup.files.find(
    (file) => file.type === "Strategic Image"
  )?.url;

  return (
    <>
      <Accordion expanded={props.expanded} onChange={props.onChange}>
        <CustomAccordionSummary>Assessment Conclusion</CustomAccordionSummary>
        <AccordionDetails>
          <SectionContainer>
            <SelectedStartupContainer>
              <Typography>{selectedStartupsText}</Typography>
              <Chip
                variant={
                  opportunitiesSelectedForPilot.length > 0 ? "choice" : "filled"
                }
                label={selectedStartupLabel}
                size="small"
                data-testid="selected-startup"
              />
            </SelectedStartupContainer>
            <TableContainer
              sx={{
                overflowX: "scroll",
              }}
              data-testid="assessment-conclusion-table"
            >
              <StyledTable $isEmpty={isTableEmpty} aria-label="Product Demos">
                <TableBody
                  sx={{
                    display: "flex",
                    width: isTableEmpty ? "100%" : "auto",
                  }}
                >
                  <StickyRow
                    $isHighlighted={
                      isTableEmpty || opportunitiesSelectedForPilot.length === 0
                    }
                  >
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell height={theme.spacing(8.5)}>
                      Startup
                    </StyledTableCell>
                    <StyledTableCell>Founding Year</StyledTableCell>
                    <StyledTableCell>Location</StyledTableCell>
                    <StyledTableCell sx={{ flexGrow: 1 }}>
                      Decision Rationale
                    </StyledTableCell>
                  </StickyRow>
                  {!isTableEmpty ? (
                    qualifiedOpportunities.map((opportunity, index) => (
                      <AssessmentConclusionRow
                        key={opportunity.id}
                        handleOpportunityStatusChange={
                          handleOpportunityStatusChange
                        }
                        handleUpdateDecisionRationale={
                          handleUpdateDecisionRationale
                        }
                        isSelectionDisabled={isSelectionDisabled}
                        opportunity={opportunity}
                        currentOpportunityIndex={currentOpportunityIndex}
                        setCurrentOpportunityIndex={setCurrentOpportunityIndex}
                        index={index}
                        project={props.project}
                      />
                    ))
                  ) : (
                    <StyledTableRow style={{ width: "100%" }}>
                      <StyledTableCell></StyledTableCell>
                      <EmptyTableCell>
                        There are no startups yet.
                      </EmptyTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </SectionContainer>
          <AssessmentReport
            projectId={props.project.id}
            opportunities={props.opportunities}
            assessmentSummary={props.assessmentSummary}
            handleSave={props.handleSave}
            genAiEnabled={
              props.project?.businessUnit?.ventureClient?.genAiEnabled ?? true
            }
            canEdit={canEdit}
          />
        </AccordionDetails>
      </Accordion>
      {currentOpportunityIndex !== null && (
        <StartupQuickOverviewModal
          handleModalClose={handleCloseQuickOverview}
          opportunity={qualifiedOpportunities[currentOpportunityIndex]}
          opportunityLogo={logoImage}
          opportunityList={qualifiedOpportunities}
          handleOpportunityStatusChange={handleOpportunityStatusChange}
          strategicImage={strategicImage}
          viewedFunnelStage="assess"
          isSelectionDisabled={
            funnelStages.indexOf(props.project.funnelStage) >= 2
          }
          showSelection={true}
          handleNavigateOpportunity={handleNavigateOpportunity}
          requirements={props.project.requirements}
        />
      )}
    </>
  );
}
