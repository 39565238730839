import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  Button,
} from "@mui/material";
import { ReactElement, useState, useReducer, Reducer, useContext } from "react";
import { Project } from "../../../../Types/Project";
import CustomAccordionSummary from "../../../UI/CustomAccordionSummary";
import PilotResourcesSection from "./PilotResourcesSection";
import PilotTeamSection from "./PilotTeamSection";
import { ProjectHttpService } from "../../../../Http/Project/Project.http.service";
import { getErrorMessage } from "../../../../utils";
import { useSnackbar } from "notistack";
import PilotBackgroundSection from "./PilotBackgroundSection";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import useSaveProject from "../../../../Hooks/useSaveProject";
import useRoles from "../../../../Hooks/useRoles";

interface Props extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSave: (withScroll: boolean) => void;
}

const BuyInsights = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { globalEditMode, setGlobalEditMode } = useContext(
    GlobalProjectEditContext
  );
  const { canEdit } = useRoles(props.project);
  const { activeStep } = useContext(GlobalProjectEditContext);
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      id: props.project.id,
      adoptionOwnerId: props.project.adoptionOwner?.id,
      adoptionOwner: props.project.adoptionOwner,
      purchasingContactId: props.project.purchasingContact?.id,
      purchasingContact: props.project.purchasingContact,
      projectLeadStartupId: props.project.projectLeadStartup?.id,
      projectLeadStartup: props.project.projectLeadStartup,
      costEstimation: props.project.costEstimation,
      firstOffer: props.project.firstOffer,
      purchaseOrderAmount: props.project.purchaseOrderAmount,
      additionalResources: props.project.additionalResources,
      pilotBackground: props.project.pilotBackground,
      sourceOfStartup: props.project.sourceOfStartup,
      sourceOfStartupComment: props.project.sourceOfStartupComment,
    } as Project
  );

  const handleCancelEdit = () => {
    if (!props.expanded) return;

    setProject({
      adoptionOwnerId: props.project.adoptionOwnerId,
      adoptionOwner: props.project.adoptionOwner,
      purchasingContactId: props.project.purchasingContactId,
      purchasingContact: props.project.purchasingContact,
      projectLeadStartupId: props.project.projectLeadStartupId,
      projectLeadStartup: props.project.projectLeadStartup,
      costEstimation: props.project.costEstimation,
      firstOffer: props.project.firstOffer,
      purchaseOrderAmount: props.project.purchaseOrderAmount,
      additionalResources: props.project.additionalResources,
      pilotBackground: props.project.pilotBackground,
      sourceOfStartup: props.project.sourceOfStartup,
      sourceOfStartupComment: props.project.sourceOfStartupComment,
    });
    setEditMode(false);
    setGlobalEditMode(false);
  };

  const isBuyStage = activeStep === 2;

  const handleSaveSection = async () => {
    if (!props.expanded) return;

    await ProjectHttpService.updateProject(project as Project)
      .then(() => {
        props.handleSave(false);
        setEditMode(false);
        setGlobalEditMode(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not save the project: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleEditButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setEditMode(true);
    setGlobalEditMode(true);
  };

  const EditButton = () => {
    return (
      <Button
        data-testid="edit-buy-insights"
        variant="contained"
        onClick={handleEditButtonClick}
        disabled={globalEditMode}
      >
        Edit
      </Button>
    );
  };

  useSaveProject(handleSaveSection);

  return (
    <Accordion
      expanded={props.expanded}
      onChange={props.onChange}
      disabled={editMode}
    >
      <CustomAccordionSummary
        actionButton={
          props.expanded && !editMode && canEdit ? <EditButton /> : null
        }
      >
        {isBuyStage ? "Buy" : "Pilot"} Insights
      </CustomAccordionSummary>
      <AccordionDetails
        sx={{ display: "flex", flexDirection: "column", gap: 5 }}
      >
        <PilotTeamSection
          project={project}
          editMode={editMode}
          onChange={setProject}
          startupId={
            props.project.opportunities?.filter(
              (opp) => opp.isSelectedForPilot
            )[0]?.startupId
          }
          ventureClientId={props.project.businessUnit?.ventureClient.id}
        />
        <PilotResourcesSection
          project={project}
          editMode={editMode}
          onChange={setProject}
        />
        <PilotBackgroundSection
          project={project}
          editMode={editMode}
          onChange={setProject}
        />
      </AccordionDetails>
      {editMode && (
        <Box display="flex" justifyContent="flex-end" p={3} gap={3}>
          <Button onClick={handleCancelEdit}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveSection}>
            Save
          </Button>
        </Box>
      )}
    </Accordion>
  );
};

export default BuyInsights;
