import { Stack } from "@mui/material";
import ContactCopy from "./ContactCopy";
import { ClientContactDTO } from "../../../Types/ClientContact";
import { StartupContactDTO } from "../../../Types/StartupContact";
import { ReactElement } from "react";

interface ContactCardContactsProps {
  contact: ClientContactDTO | StartupContactDTO;
  orientation?: "horizontal" | "vertical";
}

const ContactCardContacts = ({
  contact,
  orientation = "vertical",
}: ContactCardContactsProps): ReactElement => {
  return (
    <Stack
      flexDirection={orientation === "horizontal" ? "row" : "column"}
      gap={1}
      columnGap={4}
    >
      <ContactCopy value={contact.phone} variant="phone" />
      <ContactCopy value={contact.email} variant="email" />
    </Stack>
  );
};

export default ContactCardContacts;
