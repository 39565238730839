import { Grid, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";
import { StartupContactDTO } from "../../../../../Types/StartupContact";
import { ClientContactDTO } from "../../../../../Types/ClientContact";
import ContactCard from "../../../../Contacts/ContactCard/ContactCard";
import ContactCardPlaceholder from "../../../../Contacts/ContactCard/ContactCardPlaceholder";

interface ContactInfoProps {
  title: string;
  contacts: StartupContactDTO[] | ClientContactDTO[];
  fullwidth?: boolean;
}

export default function ContactInfo({
  title,
  contacts,
  fullwidth,
}: ContactInfoProps): ReactElement {
  return (
    <Stack gap={1} width="100%" height="100%">
      <Typography color="text.mediumEmphasis">{title}</Typography>
      <Grid container spacing={2} height="100%">
        {contacts.length > 0
          ? contacts.map((contact) => (
              <Grid item xs={fullwidth ? 6 : 12} key={contact?.id}>
                <ContactCard
                  key={contact?.id}
                  contact={contact}
                  color="gray"
                  orientation="horizontal"
                />
              </Grid>
            ))
          : contacts.length === 0 && (
              <Grid item xs={fullwidth ? 6 : 12}>
                <ContactCardPlaceholder title={title} />
              </Grid>
            )}
      </Grid>
    </Stack>
  );
}
