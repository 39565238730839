import { File as DocumentFile, FileIcons } from "../../../../../Types/File";
import { ReactElement, useContext } from "react";
import { useState } from "react";
import {
  Chip,
  debounce,
  ListItem,
  TextField,
  Typography,
  styled,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { FileHttpService } from "../../../../../Http/File/File.http.service";
import { useSnackbar } from "notistack";
import { GlobalLoaderContext } from "../../../../../Context/LoaderContext";
import theme from "../../../../../theme";
import { formatDate, getErrorMessage } from "../../../../../utils";
import { otherFileTypes } from "../../../../../Constants/Files";

//icons
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArticleIcon from "@mui/icons-material/Article";
import TableChartIcon from "@mui/icons-material/TableChart";
import DatasetIcon from "@mui/icons-material/Dataset";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import FileItemMenuButton from "./FileItemMenuButton";
import DeleteOrRemoveModal from "../../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import { DatasetLinkedOutlined } from "@mui/icons-material";
import RedirectWebsiteModal from "../../../../UI/Modals/RedirectWebsiteModal/RedirectWebsiteModal";
import useRoles from "../../../../../Hooks/useRoles";

const StyledListItem = styled(ListItem)(() => ({
  display: "flex",
  gap: theme.spacing(2),
  background: theme.palette.surface.secondary.main,
  padding: theme.spacing(2),
  borderRadius: theme.shape.radius.minimal,
  transition: "background-color 0.3s ease",
  position: "relative",
  cursor: "pointer",
  ":hover": {
    background: theme.palette.surface.primary.main,
    boxShadow: "0px 4px 24px 0px #00000014",
  },
}));

const FileTypeContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: theme.spacing(4),
}));

const TitlesContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  width: "100%",
}));

const MenuButtonContainer = styled(Box)(() => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const FileName = styled(Typography)(() => ({
  paddingRight: theme.spacing(2),
  wordBreak: "break-all",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
}));

const GenerateFileLoader = styled(CircularProgress)(() => ({
  position: "absolute",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

const iconSx = { sx: { fontSize: "16px" } };
const getFileIcon = (fileIcon: FileIcons) => {
  switch (fileIcon) {
    case "Article": {
      return <ArticleIcon {...iconSx} data-testid="article-icon" />;
    }
    case "TableChart": {
      return <TableChartIcon {...iconSx} data-testid="tablechart-icon" />;
    }
    case "Dataset": {
      return <DatasetIcon {...iconSx} data-testid="dataset-icon" />;
    }
    case "Document": {
      return <StickyNote2Icon {...iconSx} data-testid="document-icon" />;
    }
    case "Image": {
      return <HeatPumpIcon {...iconSx} data-testid="image-icon" />;
    }
    case "Link": {
      return <DatasetLinkedOutlined {...iconSx} data-testid="image-icon" />;
    }
    default: {
      return <SummarizeIcon {...iconSx} data-testid="other-icon" />;
    }
  }
};

interface FilesItemProps {
  file: DocumentFile;
  index: number;
  mapId: number | string;
  deleteFile: (fileId: number) => void;
  editingFileId?: number;
  setEditingFileId: (id?: number) => void;
  handleFileRename: (id: number, name: string, mapId: number | string) => void;
  projectId?: number;
  handleSave: () => void;
}

export interface FileItemMenuOption {
  label: string;
  action: () => void;
  visible: boolean;
  disabled?: boolean;
  isHighlighted?: boolean;
}

const FileItem = (props: FilesItemProps): ReactElement => {
  const { file } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { isViewer } = useRoles();

  const { globalLoader, setGlobalLoader } = useContext(GlobalLoaderContext);
  const [fileIdsBeingDownloaded, setFileIdsBeingDownloaded] = useState<
    number[]
  >([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showPreviewIcon, setShowPreviewIcon] = useState<number | null>();
  const [openRedirectModal, setOpenRedirectModal] = useState(false);
  const debouncedHandleFileRename = debounce(props.handleFileRename, 400);

  const fileIsBeingGenerated = file.url === null;

  const date = new Date(file.dateCreated);
  const displayDate = file.dateCreated ? formatDate(date) : "";

  const previewFile = async (
    fileId: number,
    fileFormat: string
  ): Promise<void> => {
    if (fileFormat === "PDF") {
      try {
        await FileHttpService.preview(fileId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        enqueueSnackbar(
          `Something went wrong with previewing the file: ${error.message}`,
          {
            variant: "error",
          }
        );
      }
    }
  };

  const downloadFile = async (
    fileId: number,
    fileName: string
  ): Promise<void> => {
    try {
      setFileIdsBeingDownloaded((prevState: number[]) => [
        ...prevState,
        fileId,
      ]);
      await FileHttpService.download(fileId, fileName);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      enqueueSnackbar(
        `Something went wrong with downloading the file: ${error.message}`,
        {
          variant: "error",
        }
      );
    }
    setFileIdsBeingDownloaded((prevState: number[]) =>
      prevState.filter((id: number) => id !== fileId)
    );
  };

  const publishGeneratedFile = async (
    projectId: number,
    linkableType: string,
    fileType: string,
    preview: boolean
  ) => {
    setGlobalLoader(true);
    try {
      await FileHttpService.generateFile(
        (props.mapId === "project" ? projectId : props.mapId) as number,
        linkableType,
        fileType,
        preview
      ).then(() => {
        setGlobalLoader(false);
        props.handleSave();
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      enqueueSnackbar(
        `Something went wrong with publishing the file: ${error.message} `,
        {
          variant: "error",
        }
      );
    }
    props.handleSave();
  };

  const handleIconOnMouseOver = (id: number, format: string) => {
    if (format === "PDF") {
      setShowPreviewIcon(id);
    }
  };

  const generateNewPreview = () => {
    FileHttpService.generateFile(
      (props.mapId === "project" ? props.projectId : props.mapId) as number,
      file.linkableType,
      file.type,
      file.preview
    )
      .then(() => {
        props.handleSave();
        enqueueSnackbar(
          "Document generation successfully started. It will be available for downloading soon.",
          {
            variant: "success",
          }
        );
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not generate file: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const menuItemOptions: FileItemMenuOption[] = [
    {
      label: "Publish",
      action: () =>
        publishGeneratedFile(
          props.projectId as number,
          file.linkableType,
          file.type,
          false
        ),
      visible: !fileIsBeingGenerated && file.preview && !isViewer,
      isHighlighted: true,
    },
    {
      label: "Generate New Version",
      action: generateNewPreview,
      visible: file.preview && !isViewer,
      disabled: globalLoader || fileIsBeingGenerated,
    },
    {
      label: "Download",
      action: () => downloadFile(file.id, file.name),
      visible: !file.isLink,
      disabled:
        fileIsBeingGenerated || fileIdsBeingDownloaded.includes(file.id),
    },
    {
      label: "Edit",
      action: () => props.setEditingFileId(file.id),
      visible: !file.isLink && !isViewer,
    },
    {
      label: "Copy Link",
      action: () => navigator.clipboard.writeText(file.url),
      visible: file.isLink === true,
    },
    {
      label: "Delete",
      action: () => setOpenDeleteModal(true),
      visible: !isViewer,
    },
  ];

  const isEditing = props.editingFileId === file.id;

  const saveChanges = () => {
    FileHttpService.update(file)
      .then(() => {
        props.setEditingFileId();
        props.handleSave();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not save the file: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  return (
    <>
      <StyledListItem
        disableGutters
        key={file.id}
        data-testid={"FileItem-" + file.id}
        onMouseOver={() => {
          if (fileIsBeingGenerated) return;
          handleIconOnMouseOver(file.id, file.format);
        }}
        onMouseOut={() => {
          setShowPreviewIcon(null);
        }}
        onClick={() => {
          if (fileIsBeingGenerated) return;
          if (file.isLink) return setOpenRedirectModal(true);
          previewFile(file.id, file.format);
        }}
      >
        {!isEditing && (
          <FileTypeContainer
            data-testid="file-icon"
            sx={{
              color: theme.palette.icon.primary,
              minWidth: "fit-content",
              alignSelf: "start",
            }}
          >
            {showPreviewIcon === file.id ? (
              <OpenInNewIcon {...iconSx} data-testid="preview-button" />
            ) : (
              getFileIcon(file.fileIcon)
            )}
            <Typography variant="overline" color="text.disabled">
              {file.format}
            </Typography>
          </FileTypeContainer>
        )}

        <TitlesContainer>
          {isEditing ? (
            <Box
              onClick={(e) => e.stopPropagation()}
              display="flex"
              flexDirection="column"
              gap={theme.spacing(1)}
            >
              <TextField
                label="File Name"
                id={`file-name-${file.id}`}
                name={`file-name-${file.id}`}
                variant="outlined"
                fullWidth
                defaultValue={file.name}
                onChange={(e) =>
                  debouncedHandleFileRename(
                    file.id,
                    e.target.value,
                    props.mapId
                  )
                }
              />
              <Box display="flex" gap={theme.spacing(0.5)}>
                <Button
                  fullWidth
                  onClick={() => props.setEditingFileId(undefined)}
                >
                  Cancel
                </Button>
                <Button fullWidth variant="contained" onClick={saveChanges}>
                  Save
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <FileName>
                {otherFileTypes.includes(file.type)
                  ? file.name
                  : file.displayName}
              </FileName>
              <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
                {file.preview && !isEditing && (
                  <>
                    <Chip
                      data-testid="preview-button"
                      label="Preview"
                      variant="counter"
                      color="info"
                    />
                  </>
                )}
                <Typography variant="body2" color="text.mediumEmphasis">
                  {displayDate}
                </Typography>
              </Box>
            </>
          )}
        </TitlesContainer>
        {!isEditing && (
          <MenuButtonContainer onClick={(e) => e.stopPropagation()}>
            <FileItemMenuButton options={menuItemOptions} />
          </MenuButtonContainer>
        )}
        {fileIsBeingGenerated && (
          <GenerateFileLoader color="secondary" size={16} />
        )}
      </StyledListItem>
      <DeleteOrRemoveModal
        id={1}
        entity="file"
        modalOpen={openDeleteModal}
        handleCloseModal={() => setOpenDeleteModal(false)}
        handleDelete={() => props.deleteFile(file.id)}
        actionType="delete"
      />
      <RedirectWebsiteModal
        modalOpen={openRedirectModal}
        setModalOpen={setOpenRedirectModal}
        url={file.url}
      />
    </>
  );
};

export default FileItem;
