import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { ReactElement, useState } from "react";
import useRoles from "../../../Hooks/useRoles";
import theme from "../../../theme";
import { StartupContactDTO } from "../../../Types/StartupContact";
import ContactCard from "../../Contacts/ContactCard/ContactCard";
import CreateStartupContactModal from "../../Contacts/StartupComponents/CreateStartupContactModal";
import ScrollableDialogContent from "../../UI/Modals/ScrollableDialogContent";

interface StartupTeamModalProps {
  contacts: StartupContactDTO[];
  startupId: number;
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  handleSave: () => void;
}

export default function StartupTeamModal({
  contacts,
  startupId,
  modalOpen,
  setModalOpen,
  handleSave,
}: StartupTeamModalProps): ReactElement {
  const [addContactModalOpen, setAddContactModalOpen] = useState(false);
  const { isExternalUser } = useRoles();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        data-testid="startup-team-modal"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" gap={3} alignItems="center">
            Startup Team
            <Chip label={contacts.length} variant="counter" color="info" />
          </Box>
          <IconButton
            sx={{ color: "icon.primary", p: 0, alignSelf: "flex-start" }}
            onClick={() => setModalOpen(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        </DialogTitle>
        <ScrollableDialogContent>
          <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr 1fr">
            {contacts.map((contact) => (
              <ContactCard
                key={contact.id}
                contact={contact}
                startupId={startupId}
                color={contact.founder ? "white" : "gray"}
                editable
                handleSave={handleSave}
              />
            ))}
          </Box>
        </ScrollableDialogContent>
        {!isExternalUser && (
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setAddContactModalOpen(true);
              }}
            >
              Add Contact
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {addContactModalOpen && (
        <CreateStartupContactModal
          handleSave={handleSave}
          modalOpen={addContactModalOpen}
          setModalOpen={setAddContactModalOpen}
          startupId={startupId}
        />
      )}
    </>
  );
}
