import { Close, InfoOutlined } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Box,
  Stack,
  Chip,
  Typography,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import { ReactElement, useState } from "react";
import { INITIATIVE_NAME_ARCHIVED_TOOLTIP } from "../../../../../Constants/TooltipText";
import theme from "../../../../../theme";
import { InitiativeName } from "../../../../../Types/InitiativeName";
import {
  InitiativeType,
  LeadProjectOriginType,
} from "../../../../../Types/LeadProject";
import ScrollableDialogContent from "../../../../UI/Modals/ScrollableDialogContent";
import { a11yProps, TabPanel } from "../../../../UI/TabPanel";
import InitiativeNameItem from "./InitiativeNameItem";
import { initiativeDescriptions } from "./LeadInitiativeDescriptions";
import useRoles from "../../../../../Hooks/useRoles";
import { VentureClient } from "../../../../../Types/VentureClient";

interface ManageLeadInitiativeModalProps {
  ventureClientId: number;
  initiative: InitiativeType;
  initiativeNames: InitiativeName[];
  origins: LeadProjectOriginType[];
  modalOpen: boolean;
  closeModal(): void;
  handleRefresh(): void;
}

export default function ManageLeadInitiativeModal({
  ventureClientId,
  initiative,
  initiativeNames,
  origins,
  modalOpen,
  closeModal,
  handleRefresh,
}: ManageLeadInitiativeModalProps): ReactElement {
  const { canManage } = useRoles(undefined, undefined, {
    id: ventureClientId,
  } as VentureClient);
  const [tab, setTab] = useState(0);
  const [currentEditingId, setCurrentEditingId] = useState<number | null>(null);
  const [currentInitiativeNames, setCurrentInitiativeNames] = useState(
    initiativeNames.sort((a, b) => a.value.localeCompare(b.value))
  );

  const activeInitiativeNames = currentInitiativeNames.filter(
    (initiativeName) => !initiativeName.archivedAt
  );
  const archivedInitiativeNames = initiativeNames.filter(
    (initiativeName) => initiativeName.archivedAt
  );

  const addInitiativeName = () => {
    setCurrentInitiativeNames([
      ...currentInitiativeNames,
      {
        id: -1,
        value: "",
        ventureClientId: ventureClientId,
        initiativeType: initiative,
      },
    ]);
    setCurrentEditingId(-1);
  };

  const removeInitiativeName = (id: number) => {
    setCurrentInitiativeNames(
      currentInitiativeNames.filter(
        (initiativeName) => initiativeName.id !== id
      )
    );
    if (id !== -1) handleRefresh();
  };

  const updateInitiativeName = (initiativeName: InitiativeName) => {
    const index = currentInitiativeNames.findIndex(
      (currentInitiativeName) => currentInitiativeName.id === initiativeName.id
    );
    const newInitiativeNames = currentInitiativeNames.filter(
      (initiativeName) => initiativeName.id !== -1
    );

    if (index !== -1) {
      newInitiativeNames[index] = initiativeName;
    } else {
      newInitiativeNames.push(initiativeName);
    }

    handleRefresh();
    setCurrentInitiativeNames(newInitiativeNames);
  };

  const unarchiveInitiativeName = (initiativeName: InitiativeName) => {
    const newInitiativeNames = currentInitiativeNames;
    newInitiativeNames.push(initiativeName);

    setCurrentInitiativeNames(
      newInitiativeNames.sort((a, b) => a.value.localeCompare(b.value))
    );

    handleRefresh();
  };

  return (
    <>
      <Dialog
        fullWidth
        open={modalOpen}
        onClose={closeModal}
        data-testid="manage-lead-initiative-modal"
        sx={{
          ".MuiDialog-paper": {
            gap: theme.spacing(3),
            height: "70vh",
          },
        }}
      >
        <DialogTitle>
          {initiative}
          <IconButton
            sx={{ color: "icon.mediumEmphasis" }}
            onClick={closeModal}
            size="small"
          >
            <Close fontSize="small" />
          </IconButton>
        </DialogTitle>
        <Stack gap={2} px={4}>
          <Box display="flex" gap={1} flex={1}>
            {origins.map((origin) => (
              <Chip key={origin} label={origin} variant="choice" />
            ))}
          </Box>
          <Typography color="text.mediumEmphasis">
            {initiativeDescriptions[initiative]}
          </Typography>
          <Stack gap={1}>
            <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
              <Tab
                label={
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle1">Active</Typography>
                    <Chip
                      data-testid="active-initiatives"
                      variant="counter"
                      color="info"
                      label={activeInitiativeNames.length}
                    />
                  </Box>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle1">Archived</Typography>
                    <Tooltip title={INITIATIVE_NAME_ARCHIVED_TOOLTIP}>
                      <InfoOutlined
                        sx={{ fontSize: 16 }}
                        htmlColor={theme.palette.icon.mediumEmphasis}
                      />
                    </Tooltip>
                    <Chip
                      data-testid="archived-initiatives"
                      variant="counter"
                      color="info"
                      label={archivedInitiativeNames.length}
                    />
                  </Box>
                }
                {...a11yProps(1)}
              />
            </Tabs>
          </Stack>
        </Stack>
        <ScrollableDialogContent sx={{ mt: theme.spacing(-3) }}>
          <TabPanel value={tab} index={0}>
            <Stack pt={1}>
              {activeInitiativeNames.length > 0 || currentEditingId === -1 ? (
                <>
                  {activeInitiativeNames.map((initiativeName) => (
                    <InitiativeNameItem
                      key={initiativeName.id}
                      initiativeName={initiativeName}
                      currentEditingId={currentEditingId}
                      onDelete={() => {
                        setCurrentEditingId(null);
                        removeInitiativeName(initiativeName.id);
                      }}
                      onSave={(initiativeName) => {
                        initiativeName && updateInitiativeName(initiativeName);
                      }}
                      onEditStart={() => setCurrentEditingId(initiativeName.id)}
                      onEditEnd={() => {
                        setCurrentEditingId(null);
                      }}
                    />
                  ))}
                </>
              ) : (
                <Typography color="text.mediumEmphasis" p={theme.spacing(1, 2)}>
                  No Initiatives have been added yet.
                </Typography>
              )}
            </Stack>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Stack pt={1}>
              {archivedInitiativeNames.length > 0 ? (
                archivedInitiativeNames.map((initiativeName) => (
                  <InitiativeNameItem
                    key={initiativeName.id}
                    initiativeName={initiativeName}
                    currentEditingId={currentEditingId}
                    onDelete={() => {
                      setCurrentEditingId(null);
                      handleRefresh();
                    }}
                    onSave={unarchiveInitiativeName}
                    onEditStart={() => setCurrentEditingId(initiativeName.id)}
                    onEditEnd={() => setCurrentEditingId(null)}
                  />
                ))
              ) : (
                <Typography color="text.mediumEmphasis" p={theme.spacing(1, 2)}>
                  No Initiatives have been archived yet.
                </Typography>
              )}
            </Stack>
          </TabPanel>
        </ScrollableDialogContent>
        {tab === 0 && canManage && (
          <DialogActions>
            <Button
              variant="contained"
              data-testid="create-button"
              disabled={currentEditingId === -1}
              onClick={addInitiativeName}
            >
              Add Initiative
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
