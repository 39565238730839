import { ReactElement, useState } from "react";
import { StartupContactDTO } from "../../../Types/StartupContact";
import { IconButton, Stack, styled } from "@mui/material";
import theme from "../../../theme";
import { EditOutlined } from "@mui/icons-material";
import ContactName from "./ContactName/ContactName";
import ContactCardContacts from "./ContactCardContacts";
import EditStartupContactModal from "../StartupComponents/EditStartupContactModal";
import { ClientContactDTO } from "../../../Types/ClientContact";

const ContactCardWrapper = styled(Stack, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $color = "white" }: { $color?: "gray" | "white" }) => ({
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  position: "relative",
  backgroundColor:
    $color === "white"
      ? theme.palette.surface.primary.main
      : theme.palette.background.default,
  boxShadow: $color === "white" ? theme.boxShadows[2] : "none",
  borderRadius: theme.shape.radius.minimal,
  width: "100%",
  height: "100%",
  justifyContent: "space-between",
}));

type ContactCardProps = {
  contact: StartupContactDTO | ClientContactDTO;
  startupId?: number;
  color?: "white" | "gray";
  orientation?: "horizontal" | "vertical";
} & (
  | { editable?: true; handleSave(): void }
  | { editable?: false; handleSave?: undefined }
);

export default function ContactCard({
  color,
  editable,
  contact,
  startupId,
  handleSave,
  orientation = "vertical",
}: ContactCardProps): ReactElement {
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <>
      <ContactCardWrapper $color={color}>
        <ContactName
          name={contact?.name}
          title={contact?.title}
          orientation={orientation}
        />
        <ContactCardContacts contact={contact} orientation={orientation} />
        {editable && (
          <IconButton
            size="small"
            sx={{ position: "absolute", right: theme.spacing(1) }}
            onClick={() => {
              setEditModalOpen(true);
            }}
          >
            <EditOutlined
              fontSize="small"
              htmlColor={theme.palette.icon.primary}
            />
          </IconButton>
        )}
      </ContactCardWrapper>
      {editModalOpen && "startupId" in contact && editable && (
        <EditStartupContactModal
          contact={contact as StartupContactDTO}
          handleSave={handleSave}
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
          startupId={startupId}
        />
      )}
    </>
  );
}
