import { Avatar, Box, Stack, styled, Typography } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../theme";
import {
  MailOutline,
  PermIdentityOutlined,
  PhonelinkRing,
} from "@mui/icons-material";

const ContactCardWrapper = styled(Stack)({
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  position: "relative",
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.radius.minimal,
  width: "100%",
  height: "100%",
  justifyContent: "space-between",
});

const ContactAvatar = styled(Avatar)({
  width: 32,
  height: 32,
  backgroundColor: theme.palette.surface.cool,
  color: theme.palette.icon.primaryInvert.mediumEmphasis,
});

const ContactCopyWrapper = styled(Box)(() => ({
  display: "flex",
  minWidth: 96,
  gap: theme.spacing(1),
  color: theme.palette.text.action.main,
  alignItems: "center",
  fontSize: 16,
}));

interface ContactCardPlaceholderProps {
  title: string;
}

export default function ContactCardPlaceholder({
  title,
}: ContactCardPlaceholderProps): ReactElement {
  return (
    <ContactCardWrapper>
      <Box display="flex" gap={2}>
        <ContactAvatar>
          <PermIdentityOutlined />
        </ContactAvatar>
        <Stack justifyContent="center">
          <Typography
            className="contact-name"
            variant="subtitle2"
            color="text.disabled"
          >
            {title}
          </Typography>
          <Typography variant="body2" color="text.disabled">
            --
          </Typography>
        </Stack>
      </Box>
      <Box display="flex" gap={4}>
        <ContactCopyWrapper>
          <Box display="flex" color="icon.disabled">
            <PhonelinkRing fontSize="inherit" />
          </Box>
          <Typography variant="body2" color="text.disabled">
            --
          </Typography>
        </ContactCopyWrapper>
        <ContactCopyWrapper>
          <Box display="flex" color="icon.disabled">
            <MailOutline fontSize="inherit" />
          </Box>
          <Typography variant="body2" color="text.disabled">
            --
          </Typography>
        </ContactCopyWrapper>
      </Box>
    </ContactCardWrapper>
  );
}
