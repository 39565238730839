import { ReactElement } from "react";
import CustomMenu from "../../../../UI/CustomMenu";

interface Props {
  archiveFocusArea?: () => void;
  unarchiveFocusArea?: () => void;
  setEditModalOpen: (state: boolean) => void;
  setDeleteModalOpen: (state: boolean) => void;
}

const FocusAreaMenu = (props: Props): ReactElement => {
  // Dynamically determine archive/unarchive action
  const isArchived = Boolean(props.unarchiveFocusArea);
  const archiveActionLabel = isArchived ? "Unarchive" : "Archive";
  const archiveActionFunction = isArchived
    ? props.unarchiveFocusArea
    : props.archiveFocusArea;

  const actions = [
    {
      label: "Rename",
      action: () => props.setEditModalOpen(true),
    },
    ...(archiveActionFunction
      ? [
          {
            label: archiveActionLabel,
            action: archiveActionFunction,
          },
        ]
      : []),
    {
      label: "Delete",
      action: () => props.setDeleteModalOpen(true),
    },
  ];

  return <CustomMenu entity="focus-area" actions={actions} />;
};

export default FocusAreaMenu;
